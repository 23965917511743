section {
	opacity: 1;
	transition: opacity 500ms ease-in-out;
}

header,
footer {
	clear: both;
}

.removed {
	display: none;
}

.invisible {
	opacity: 0.2;
}

.note {
	font-style: italic;
	font-size: 130%;
}

.videoView,
.detectOnClick,
.blend-shapes {
	position: relative;
	float: left;
	width: 48%;
	height: 200vh;
	margin: 2% 1%;
	cursor: pointer;
}

.videoView p,
.detectOnClick p {
	position: absolute;
	padding: 5px;
	background-color: #007f8b;
	color: #fff;
	border: 1px dashed rgba(255, 255, 255, 0.7);
	z-index: 2;
	font-size: 12px;
	margin: 0;
}

.highlighter {
	background: rgba(0, 255, 0, 0.25);
	border: 1px dashed #fff;
	z-index: 1;
	position: absolute;
}

.canvas {
	z-index: 1;
	position: absolute;
	pointer-events: none;
}

.output_canvas {
	transform: rotateY(180deg);
	-webkit-transform: rotateY(180deg);
	-moz-transform: rotateY(180deg);
}

.detectOnClick {
	z-index: 0;
}

.detectOnClick img {
	width: 100%;
}

.blend-shapes-item {
	display: flex;
	align-items: center;
	height: 20px;
}

.blend-shapes-label {
	display: flex;
	width: 120px;
	justify-content: flex-end;
	align-items: center;
	margin-right: 4px;
}

.blend-shapes-list {
	overflow: scroll;
	max-height: 500px;
	border: 1px solid black;
	padding: 20px 80px;
}

.blend-shapes-value {
	display: flex;
	height: 16px;
	align-items: center;
	background-color: #007f8b;
}

.facial-wrapper-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	.selfie-video-camera {
		clear: both;
		display: block;
		transform: rotateY(180deg);
		-webkit-transform: rotateY(180deg);
		-moz-transform: rotateY(180deg);
	}
}
.succuss-gif {
	width: 120px;
	height: 120px;
}
.succuss-gif-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 90vh;
}
.succuss-message-label {
	font-size: 24px;
	font-weight: 600;
}
.waiting-box {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	height: 80vh;
}
.waiting-loader {
	width: 100px;
	height: 100px;
}
.success-class {
	display: none;
}

.overlayDiv {
	border-radius: 60px;
	position: absolute;
	top: 45%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 400px;
	height: 450px;
	// border: 10px solid #FF0000; /* Set the border size and color */
	box-sizing: border-box; /* Make sure the border size is included in the width and height */
	z-index: 2;
	background: transparent;
	box-shadow: 0 0 0 99999rem rgba(0, 0, 0, 0.5);
	display: flex;
	flex-flow: column wrap;
	align-content: space-between;
}
.overlayDiv-border {
	border: 10px solid #389f75;
}
.overlayDiv-border-red {
	border: 10px solid #ff4545;
}

.right-part-move {
	border-bottom-left-radius: 60px;
	border-top-left-radius: 60px;
	width: 150px;
	height: 450px;
	border-top: 10px solid #fff; /* Set the border size and color */
	border-left: 10px solid #fff; /* Set the border size and color */
	border-bottom: 10px solid #fff; /* Set the border size and color */
	box-sizing: border-box; /* Make sure the border size is included in the width and height */
	// border-color: #fff;
	z-index: 2;
}

.success-rightMove {
	border-color: #389f75 !important;
	transition: #389f75 0.5s ease-in-out;
}

.hide-class-text {
	display: none !important;
}

.left-part-move {
	border-bottom-right-radius: 60px;
	border-top-right-radius: 60px;
	width: 150px;
	height: 450px;
	border-top: 10px solid #fff; /* Set the border size and color */
	border-right: 10px solid #fff; /* Set the border size and color */
	border-bottom: 10px solid #fff; /* Set the border size and color */
	// border-color: #fff;
	box-sizing: border-box; /* Make sure the border size is included in the width and height */
	z-index: 2;
}

.info-container {
	position: absolute;
	bottom: 0%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
}
.info-container-selfie {
	position: absolute;
	bottom: -5%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
}
.capture-btn-selfie {
	padding-left: 200px;
	padding-right: 200px;
}

.selfie-image-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.info-instruction {
	background: #0000003d;
	padding: 5px 10px 5px 10px;
	color: #fff;
}
.info-text__facial-enroll {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: var(--font-size-5);
}
.mb-2 {
	margin-bottom: 2%;
}

.long-arrow-right__facial-enroll {
	display: block;
	margin: 30px auto;
	width: 25px;
	height: 25px;
	border-top: 5px solid #fff;
	border-left: 5px solid #fff;
}

#selfie-text__facial-enroll {
	display: none;
}

.capture-btn-selfie-hide__facial-enroll {
	display: none;
}

.mt-2 {
	margin-top: 2%;
}

.long-arrow-right__facial-enroll {
	transform: rotate(135deg);
}
.long-arrow-left {
	transform: rotate(-45deg);
}
.long-arrow-right__facial-enroll::after,
.long-arrow-left::after {
	content: '';
	display: block;
	width: 5px;
	height: 45px;
	background-color: #fff;
	transform: rotate(-45deg) translate(15px, 4px);
	left: 0;
	top: 0;
}

.hide-video-webcam__facialEnroll {
	visibility: hidden;
}
//  .facial-modal-load__facialEnroll{
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   // height: 100vh;
//   // // width: 100vw;
//  }

@media screen and (max-width: 1280px) {
	.overlayDiv {
		width: 350px;
		height: 400px;
	}
	.left-part-move {
		width: 150px;
	}
	.right-part-move {
		width: 150px;
	}
}

@media screen and (max-width: 500px) {
	.overlayDiv {
		width: 300px;
		height: 350px;
	}

	.left-part-move {
		width: 100px;
	}
	.right-part-move {
		width: 100px;
	}
	.capture-btn-selfie {
		position: absolute;
		// top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 60%;
		padding-left: 150px;
		padding-right: 150px;
		margin-top: 12%;
	}
	.info-container {
		width: 80%;
	}
	.info-container-selfie {
		bottom: 12%;
	}
	.info-instruction {
		padding: 0px 0px 0px 0px !important;
	}
	.mb-2 {
		margin-bottom: 0%;
		width: 40vh !important;
	}
}
.rotate-modal {
	height: calc(100vh - 1px);
	width: 100vw;
	border-radius: unset;
}
.rotate-modal--body-wrapper {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}
.rotate-logo {
	font-size: 50px;
}

.rotate-logo-desp {
	font-size: 20px;
}
.rotate-modal {
	height: calc(100vh - 1px);
	width: 100vw;
	border-radius: unset;
}
.rotate-modal--body-wrapper {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}
.rotate-logo {
	font-size: 50px;
}

.rotate-logo-desp {
	font-size: 20px;
}

.camera-option__dropdown-container {
	display: flex;
	align-items: center;
	border: 1px solid #ccc;
	border-radius: 4;
	margin-top: 2%;
	width: 300px;
	// border-radius: calc(var(--border-radius) * 0.5);
	@media screen and (max-width: 420px) {
		width: unset;
	}
}
.camera-icon-box {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 48px;
	height: 52px;
	// border: 1px solid #ccc;
	// border-radius: 8px;
	border-right: 1px solid #0000001f;
	background-color: #f5f8ff;
	// border-color: hsl(0, 0%, 80%);
}

.camera-icon {
	font-size: 24px;
	color: #7c8398;
}
