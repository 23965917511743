.Facial__Qr {
	// Wrapper block for the QR code container, used to structure and center elements
	&--wrapper {
		display: flex;
		flex-direction: column;
		gap: 12px;
		align-items: center;
	}

	// Block for the separator line with optional text in the center
	&--separate {
		font-size: var(--font-size-3);
		font-weight: 500;
		line-height: 20px;
		text-align: center;
		color: var(--color-text-light-80);
		display: flex;
		gap: 4px;
		align-items: center;
		justify-content: center;
		width: 100%;
	}
	&--separate::before,
	&--separate::after {
		content: '';
		flex: 1;
		border-bottom: 1px solid rgba(128, 128, 128, 0.4); // Light gray line for the separator
	}

	// Block for the title text, styled for prominence
	&--title {
		font-size: var(--font-size-4);
		font-weight: 600;
		line-height: 24px;
		text-align: center;
	}
}
