.ExistSession__bodyWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.ExistSession__sessionDetails,
.ExistSession__chooseOption {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.ExistSession__detailsTitle,
.ExistSession__optionHeadTitle {
  font-size: var(--font-size-3);
  font-weight: 500;
  line-height: 20px;
}
.ExistSession__detailsTable {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.ExistSession__tableRow {
  display: flex;
  font-size: var(--font-size-3);
  font-weight: 500;
  line-height: 20px;
}
.ExistSession__tableKey {
  flex: 1;
  color: var(--color-text-light-70);
  padding-right: 16px;
}
.ExistSession__tableValue {
  flex: 1;
  padding-left: 16px;
}
.ExistSession__iconImage {
  width: 52px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-6);
  background : #0051cc;
  border-radius: 50%;
  color: var(--color-white);
}
.ExistSession__OptionWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.ExistSession__OptionTitle {
  font-size: var(--font-size-3);
  color: var(--color-text-light-80);
}
.ExistSession__OptionSubTitle {
  font-size: var(--font-size-2);
  color: var(--color-text-light-70);
}
.ExistSession__nextIcon {
  font-size: var(--font-size-5);
}

.ExistSession__title-wrapper {
  width: 100%;
  gap: 6px;
  padding-bottom: 16px;
  &--title {
    font-weight: 600;
    font-size: var(--font-size-5);
    line-height: calc(var(--line-height-2) * 1.75);
    color: var(--color-text-90-light);
    letter-spacing: 0;
  }
  &--subtitle {
    font-weight: 400;
    font-size: var(--font-size-2);
    line-height: 16px;
    color: var(--color-text-70-light);
  }
}

.ExistSession__list-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .list-item {
        display: flex;
        align-items: center;
        gap: calc(var(--margin) * 0.5);
        width: 100%;
        height: 60px;
        border-bottom: 1px solid var(--color-input-border-light);
        padding: 0 8px;
        .list-text {
          flex-grow: 1;
        }
        &:hover {
          background-color: var(--color-hover-light-100);
          transition: 0.3s ease-in all;
          border-radius: 8px;
          cursor: pointer;
        }
      }
}
