.ReactModal__Body--open {
	overflow: hidden;
}

.ReactModal__Overlay--after-open {
	display: flex;
}

.Modal__box {
	width: 517px;
	min-height: 362px;
	border-radius: 4px;
	box-shadow: var(--shadow);
	border: solid 1px var(--color-border-2);
	background-color: var(--color-white);
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: relative;
}

.Modal {
	display: flex;
	background-color: transparent;
	max-width: 1000px;
	align-items: center;
	margin: auto;
	// padding: 10px;
}

.Modal:focus-visible {
	outline: none;
}

.ReactSettings_Modal.Modal__wrapper {
	width: 572px;
}

.Modal__wrapper {
	border-radius: 15px;
	padding: 24px;
	background-color: var(--color-bg-100-light);
	width: 100%;
	position: relative;
}

.Modal__closeButton {
	position: absolute;
	cursor: pointer;
	background-color: transparent;
	border: none;
	top: 0;
	right: 16px;
	margin-top: 16px;
	cursor: pointer;
}

.Modal__closeButton:hover .Modal__closeIcon {
	color: var(--color-black);
}

.Modal__closeIcon {
	color: #cccc;
	font-size: 28px;
}

.Modal__hidden {
	display: none;
}

.Modal__header {
	font-size: 24px;
	line-height: 36px;
	font-weight: 700;
	color: var(--c-ink);
	margin-bottom: 24px;
}

.Modal__body {
	max-width: 100%;
	width: 413px;
	margin-top: 36px;
	margin-bottom: 36px;
	font-size: 14px;
	line-height: 24px;
	color: var(--c-ink-light);
}

.Modal__buttons-wrp {
	display: grid;
	grid-template-columns: auto auto;
	column-gap: 20px;
	justify-content: center;
}

.Modal__buttons-wrp button {
	min-width: unset;
	height: unset;
}

.ReactModal__Overlay {
	opacity: 0;
	transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
	opacity: 1;
}

.ReactModal__Overlay--before-close {
	opacity: 0;
}
