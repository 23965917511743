:root {
	--font-size-1: 10px;
	--font-size-2: 12px;
	--font-size-3: 14px;
	--font-size-4: 16px;
	--font-size-5: 20px;
	--font-size-6: 24px;
	--font-size-7: 32px;

	// Window content width
	--window-viewport: 870px;
}
