.BetterPhotoTips {
	&__tips-container {
		width: 100%;
		border: 2px dashed #f38a20;
		background-color: #e88a2c1f;
		border-radius: 4px;
		padding: 8px;
		gap: 4px;
		display: grid;
		@media screen and (max-width: 1080px) and (min-width: 768px) {
			display: none;
		}
		&__tips-icon {
			color: #f38a20;
			font-size: 24px;
		}

		&__tips-title {
			font-size: 16px;
			line-height: 24px;
			display: flex;
			font-weight: 500;
		}

		&__tips-list {
			margin-left: 20px;
			font-size: 14px;
		}
	}
}
