.GlareDetectedScreen__container {
	display: flex;
	width: 100%;
	padding: 16px 0px;
	flex-direction: column;
	align-items: flex-start;
	flex-shrink: 0;

	.ppl-screen-body__label-container {
		padding: 0;
	}

	&__heading {
		display: flex;
		width: 100%;
		padding: 16px;
		flex-direction: column;
		align-items: flex-start;
		gap: 24px;
		border-bottom: 1px solid #dfe3ec;
		background: #f4f5f5;
		color: #363a45;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px; /* 142.857% */
		align-self: stretch;
	}
	&__secondcontainer {
		display: flex;
		width: 100%;
		padding: 16px 0px;
		flex-direction: column;
		align-items: center;
		gap: 16px;

		&__detected {
			display: flex;
			padding: 0px 0px;
			flex-direction: column;
			align-items: flex-start;
			gap: 4px;
			align-self: stretch;

			&__heading {
				width: 100%;
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 24px; /* 150% */
			}

			&__info {
				align-self: stretch;
				color: #6c7489;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 16px; /* 133.333% */
			}
		}

		&__tips {
			display: flex;
			padding: 8px;
			flex-direction: column;
			align-items: flex-start;
			gap: 4px;
			align-self: stretch;
			border-radius: 4px;
			border: 1px dashed #e88a2c;
			background-color: hsla(0, 80%, 60%, 0.12);

			&__heading {
				color: #363a45;
				font-size: 16px;
				font-weight: 500;
				align-self: stretch;
				display: flex;
				align-items: center;
				gap: 4px;
				&__error-icon {
					color: var(--color-danger-dark);
				}
			}

			&__steps {
				color: #515767;
				font-size: 12px;
				font-weight: 400;
				align-self: stretch;

				&__bullet {
					padding-left: 16px;
				}
			}
		}

		&__image {
			display: flex;
			width: 100%;
			height: 216px;
			padding: 0px 16px;
			justify-content: center;
			align-items: center;
			gap: 8px;

			&__imgdiv {
				height: 220px;
				flex-shrink: 0;
				border-radius: 8px;
				border: 2px dashed red;

				&__imagerotate {
					height: 216px;
					width: 358px;
					border-radius: 8px;
					object-fit: contain;
				}
			}
		}

		&__button {
			justify-content: center;
			display: flex;
			padding: 16px 24px;
			align-items: flex-start;
			gap: 8px;
			border-radius: 8px;
			background: var(--color-primary-light);
			color: #fff;
			text-align: center;
			font-size: 14px;
			font-weight: 500;
			cursor: pointer;
		}
	}
}

@media screen and (max-width: 450px) {
	.GlareDetectedScreen__container__secondcontainer__button {
		width: 100%;
	}
	.GlareDetectedScreen__container__secondcontainer__image__imgdiv__imagerotate {
		width: 100%;
	}
	.GlareDetectedScreen__container__secondcontainer__image {
		padding: unset;
	}
	.GlareDetectedScreen__container__secondcontainer__image__imgdiv {
		width: 100%;
	}
}
