.LinkExpired--container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 80vh;
	text-align: center;

	&__text-bold {
		color: var(--color-text-light-90);
		text-align: center;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		@media screen and (max-width: 768px) {
			font-size: 16px;
		}
	}

	&__text-regular {
		color: var(--color-text-light-60);
		text-align: center;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
	}

	&__image {
		max-width: 100%;
		height: auto;
		margin-bottom: 10px;
	}
}
