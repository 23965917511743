.please-wait-wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  position: absolute;
  &__inner {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
