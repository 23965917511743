.kyc-select-country {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	padding: 0 24px;
	position: relative;

	&__container {
		padding: 40px 0 40px 0;
		display: flex;
		flex-direction: column;
		gap: 24px;

		&__title-container {
			&__label {
				font-weight: 600;
				font-size: var(--font-size-4);
				line-height: calc(var(--line-height-4) * 1.5rem);
				color: var(--color-text-90-light);
				margin-bottom: 10px;
			}

			&__title {
				font-weight: 600;
				font-size: var(--font-size-5);
				line-height: 30px;
				color: var(--color-text-90-light);
				letter-spacing: 0;
				margin-bottom: 8px;
			}

			&__sub-title {
				font-weight: 400;
				font-size: var(--font-size-4);
				line-height: 20px;
				color: var(--color-text-70-light);
			}
		}
	}

	&__btn-container {
		margin-bottom: 20px;
		@media screen and (max-width: 576px) {
			position: fixed;
			width: calc(100% - 48px);
			bottom: 0;
		}
	}

	&__dropdown {
		display: flex;
		align-items: center;
		cursor: pointer;
		gap: 8px;

		&--option {
			background-color: #ffffff;
			border-bottom: unset;
			border-radius: 4px;
			padding: 8px 12px;
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			display: flex;
			align-items: center;
			gap: 8px;
			&-flag {
				font-size: 24px;
			}
			&:hover {
				background-color: #3c65d60f;
				cursor: pointer;
			}
		}
	}
}
