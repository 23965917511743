#fountainTextG {
  margin: auto;
}

.loader-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fountainTextG {
  color: blue;
  font-size: 30px;
  text-decoration: none;
  font-weight: normal;
  font-style: normal;
  float: left;
  animation-name: bounce_fountainTextG;
  -o-animation-name: bounce_fountainTextG;
  -ms-animation-name: bounce_fountainTextG;
  -webkit-animation-name: bounce_fountainTextG;
  -moz-animation-name: bounce_fountainTextG;
  animation-duration: 2.09s;
  -o-animation-duration: 2.09s;
  -ms-animation-duration: 2.09s;
  -webkit-animation-duration: 2.09s;
  -moz-animation-duration: 2.09s;
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-direction: normal;
  -o-animation-direction: normal;
  -ms-animation-direction: normal;
  -webkit-animation-direction: normal;
  -moz-animation-direction: normal;
  transform: scale(0.5);
  -o-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
}
@keyframes bounce_fountainTextG {
  0% {
    transform: scale(1);
    color: rgb(70, 56, 147);
  }

  100% {
    transform: scale(0.5);
    color: rgb(255, 255, 255);
  }
}

@-o-keyframes bounce_fountainTextG {
  0% {
    -o-transform: scale(1);
    color: rgb(0, 0, 0);
  }

  100% {
    -o-transform: scale(0.5);
    color: rgb(255, 255, 255);
  }
}

@-ms-keyframes bounce_fountainTextG {
  0% {
    -ms-transform: scale(1);
    color: rgb(0, 0, 0);
  }

  100% {
    -ms-transform: scale(0.5);
    color: rgb(255, 255, 255);
  }
}

@-webkit-keyframes bounce_fountainTextG {
  0% {
    -webkit-transform: scale(1);
    color: rgb(0, 0, 0);
  }

  100% {
    -webkit-transform: scale(0.5);
    color: rgb(255, 255, 255);
  }
}

@-moz-keyframes bounce_fountainTextG {
  0% {
    -moz-transform: scale(1);
    color: rgb(0, 0, 0);
  }

  100% {
    -moz-transform: scale(0.5);
    color: rgb(255, 255, 255);
  }
}
