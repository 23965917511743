.skelton-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100vh;
	height: 100dvh;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

	background: var(--color-bg-100-light);
	&__header {
		position: sticky;
		top: 0;
		z-index: 10;
	}
	&__body {
		height: calc(100% - 71px);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: calc(var(--margin) * 1.5);
		//max-height: 900px;
		overflow: auto;
	}
	&__footer {
		width: 100%;
		height: 48px;
		background-color: var(--color-bg-100-light);
		z-index: 99;
		position: fixed;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
	}
}
@media screen and (min-width: 500px) {
	.skelton-wrapper {
		width: 100%;
		border-radius: 0px;
	}
}

@media screen and (max-width: 768px) {
	.skelton-wrapper-questionnare {
		overflow: unset !important;
		.accred-wrapper .ppl-screen-body__body .iframe-wrapper.iframe-wrapper-506b {
			overflow: unset !important;
		}
	}
}
