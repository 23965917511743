.circle-loader {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
}

.loader {
	border-radius: 50%;
	animation: spin 2s linear infinite;
}
.loader-blue {
	border: 3px solid var(--color-btn-primary);
	border-top: 3px solid var(--color-bg-light-90);
}
.loader-white {
	border: 3px solid var(--color-bg-light-90);
	border-top: 3px solid var(--color-btn-primary);
}
.gif-loader {
	width: 100px;
	height: 100px;
}
.custom-gif-loader {
	width: 180px;
	height: 180px;
}
.wrapper-gif-loader {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 8px;
}
.wrapper-countdown-loader {
	font-size: 54px;
	font-weight: 600;
}
.GifLoader--container {
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 4px;
	display: flex;

	&__heading {
		color: black;
		font-size: 20px;
		font-weight: 600;
		word-wrap: break-word;
	}
	&__subHeading {
		align-self: stretch;
		text-align: center;
		color: #6c7489;
		font-size: 14px;
		font-weight: 400;
		word-wrap: break-word;
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
