.input-checkbox-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: 0px 8px;

  &__checkbox {
    transform: scale(1.3);
    margin-top: 5px;
  }
  &__checkbox-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #464c5c;
  }
}

.input-text-area {
  background: #f5f8ff;
  border: 2px solid #d7dff5;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  padding: 8px;
}
