.ProofReadingDocument--container {
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
	height: 89vh;
	justify-content: space-between;
	padding: 24px;
	&__header {
		padding-left: 4px;
		color: var(--Color-Text-Light-90, #363a45);
		font-size: 14px;
		font-weight: 600;
		&--label {
			padding: 8px;
			background: var(--color-bg-90-light);
			border-bottom: 1px solid var(--color-input-border-light);
			border-top: 1px solid var(--color-input-border-light);
			width: 100%;
			top: 0;
			&__text {
				font-weight: 600;
				font-size: var(--font-size-4);
				line-height: calc(var(--line-height-4) * 1.5rem);
				color: var(--color-text-90-light);
				word-break: break-all;
			}
		}
	}
	&__body {
		width: 100%;
		min-height: calc(100vh - 285px);
		max-height: 70vh;
	}
	&__data {
		width: 100%;
		height: calc(100vh - 285px);
		overflow: auto;
	}

	&__bodyElement:not(:first-child) {
		margin: 0;
		padding: 24px 0 0 0;
		width: 100%;
	}
	&__loading {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 400px;
	}
	&__header {
		position: -webkit-sticky;
		position: sticky;
		top: -1px;
		z-index: 1;
	}

	&__content {
		margin: 0;
		padding: 0 0 0 12px;
		color: var(--Color-Text-Light-80, #515767);
		font-size: 14px;
		font-weight: 400;
		overflow: visible;
	}

	&__footer {
		align-self: center;
		background: #fff;
		bottom: 0;
		border-top: 1px solid var(--Color-Border-Light-100, #dfe3ec);
		display: flex;
		flex-direction: column;
		// Shahbaaz: Due to button hide on scroll we set position fixed and set width 90%
		position: fixed;
		width: 90%;
		@media screen and (min-width: 768px) {
			position: sticky;
			width: 100%;
		}
		.aggrement-footer--container {
			max-height: 15vh;
			overflow: auto;
		}

		.aggrement--footer--data {
			gap: calc(var(--margin) * 0.5);
			padding: 8px 2px;
			&__checkbox {
				cursor: pointer;
				float: left;
				margin-top: 4px;
				margin-right: 8px;
				&:checked {
					accent-color: var(--color-primary-light);
				}
			}
			&__label {
				color: var(--Color-Text-Light-70, #6c7489);
				font-size: 14px;
				font-weight: 500;
				margin-left: 23px;
				span {
					color: var(--color-primary-light);
					font-size: 14px;
					font-weight: 500;
					cursor: pointer;
					a {
						text-decoration: none;
					}
				}
			}
		}
		&__btn {
			display: flex;
			padding: 0px 16px;
			flex-direction: column;
			align-items: center;
			gap: 8px;
			width: 100%;
		}
	}
}
