.InvestmentNotAllowed__container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	h2 {
		font-weight: 600;
		font-size: var(--font-size-4);
		line-height: calc(var(--line-height-4) * 1.5rem);
		color: var(--color-text-90-light);
		display: flex;
		align-items: center;
	}
	p {
		font-size: 14px;
		color: var(--color-text-60-light);
		padding-bottom: 16px;
	}
	.button-wrapper {
		padding: 8px var(--padding);
	}
}

.InvestmentNotAllowed__content {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	background: white;
	padding: 24px;
	width: 100%;
	gap: 8px;
	max-width: 600px;
}

.InvestmentNotAllowed__icon {
	width: 120px;
	height: 120px;
}

.InvestmentNotAllowed__img {
	width: 100%;
	height: 100%;
}
