.cd-wrapper {
  width: 100%;
  height: 100%;
  .input {
    height: unset !important;
  }
  &__header {
    width: 100%;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    &__title {
      font-weight: 600;
      font-size: var(--font-size-5);
      line-height: calc(var(--line-height-2) * 1.75);
      color: var(--color-text-90-light);
      letter-spacing: 0;
    }
    &__sub-title {
      font-weight: 400;
      font-size: var(--font-size-2);
      line-height: 16px;
      color: var(--color-text-70-light);
    }
  }
}

.empty-card-image-wrapper {
  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;
  img {
    transform: rotate(90deg);
  }
}

.d-flex {
  display: flex;
  align-items: center;
}

.gap-8 {
  gap: 8px;
}
