.UnauthorizedDomain__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.UnauthorizedDomain__body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.UnauthorizedDomain__oops {
  color: #d58888;
  font-size: 14px;
  font-weight: 500;
  padding: 0 16px;
  text-align: center;
}

.UnauthorizedDomain__todo,
.UnauthorizedDomain__owner,
.UnauthorizedDomain__examples,
.UnauthorizedDomain__wildcard {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  font-size: 14px;
  color: #3b393a;
}

.UnauthorizedDomain__need-help {
  width: 100%;
  display: flex;
  gap: 8px;
  font-size: 14px;
  color: #3b393a;
}

.UnauthorizedDomain__todo--title,
.UnauthorizedDomain__ownerTitle,
.UnauthorizedDomain__exampleTitle,
.UnauthorizedDomain__wildcard--title {
  font-weight: 600;
}
.UnauthorizedDomain__ownerStep,
.UnauthorizedDomain__example,
.UnauthorizedDomain__wildcard--subTitle {
  display: list-item;
  list-style: number;
  list-style-position: inside;
  padding-left: 16px;
}
.UnauthorizedDomain__ownerStep {
  &--bold {
    font-weight: 600;
  }
}
