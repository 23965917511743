.EmailVerificationContainer {
	width: 100%;
}
.EmailVerification {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;
	&--img {
		height: 140px;
	}
	&--details {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 8px;
		&__text {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}
		h1 {
			font-weight: 600;
			font-size: var(--font-size-5);
			color: var(--color-text-140-light);
		}
		p {
			color: var(--color-text-130-light);
			font-weight: 400;
			font-size: var(--font-size-3);
			text-align: center;
		}
		b {
			color: var(--color-text-140-light);
		}

		&__underline {
			border: none;
			border-top: 1px solid #d3d3d3;
			width: 100%;
		}
	}
}
.verify-modal {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	gap: 24px;
	&__image {
		margin-top: 25px;

		& > img {
			width: 120px;
			height: 120px;
		}

		.ri-alert-fill {
			color: var(--color-red);
			font-size: calc(8 * var(--font-size-2));
		}
	}
	&__verify-text-container {
		display: flex;
		justify-content: center;
		align-items: center;
		max-width: 505px;
		align-items: center;
		gap: 10px;
	}
	&__title-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 4px;
		.title {
			color: var(--color-bg-100-dark);
			font-size: var(--font-size-5);
			font-weight: 600;
			line-height: 28px;
			text-align: center;
		}
		.sub-title {
			color: var(--color-text-130-light);
			font-size: var(--font-size-3);
			line-height: 24px;
			text-align: center;
		}
	}
	&__footer {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 4px;
		margin-top: 16px;

		.footer-text {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 4px;
			color: var(--color-text-130-light);
			font-size: 14px;
			font-weight: 400;
		}

		&--box {
			display: flex;
			gap: 4px;
			align-items: center;
			justify-content: center;
			font-size: 16px;
			@media (max-width: 768px) {
				flex-direction: column;
			}
		}

		&--resend {
			display: flex;
			gap: 4px;
			align-items: center;
			justify-content: center;
			@media (max-width: 768px) {
				flex-direction: column;
			}
		}

		&--time {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			gap: 4px;
		}

		&--timer {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 4px;
			gap: 16px;
			border-radius: 4px;
			border: 1px solid var(--color-shadow-black);
			color: var(--color-burnt-orange);
			font-size: 16px;
			font-weight: 500;
			min-width: 32px;
		}

		&--colon {
			color: var(--color-burnt-orange);
			font-size: 16px;
			font-weight: 500;
		}

		&--btn {
			font-size: 14px;
			font-weight: 500;
			text-decoration-line: underline;
			color: var(--color-blue-dark-100);
			cursor: pointer;
		}
	}
}
