.complex-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 12px 0px;
  &__form-header {
    font-size: 20px;

  }
  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  &__item {
    height: 68px;
    width: 100%;
    border: 1px solid var(--color-primary-light);
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    font-weight: 600;
    cursor: pointer;
    &:hover {
      background-color: var(--color-primary-light);
      color: white;
    }
    i {
      font-size: 24px;
    }
  }
}
