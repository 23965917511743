@media screen and (min-width: 500px) {
	.mobile-view {
		width: 100%;
		display: flex;
		justify-content: center;
		max-width: 900px;
		margin: auto;
	}
	.acc-mobile-view {
		max-width: 90%;
		@media screen and (max-width: 768px) {
			max-width: 100%;
		}
	}
	.scan-view {
		max-width: 420px;
		border-radius: 6px;
		border: 2px solid hsla(0, 0, 0, 0.18);
	}
}
.iframe-mobile-view {
	max-width: 100% !important;
}
.loading-steps {
	position: absolute;
	display: grid;
	place-items: center;
	inset: 0;
}

.skip-btn_wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}
.root-skip-btn {
	cursor: pointer;
	font-weight: 500;
	font-size: 14px;
	color: #d58888;
	border: 1px solid #d58888;
	padding: 4px 8px;
	border-radius: 8px;
}

.sd-body.sd-body--static {
	max-width: 1400px !important;
}
