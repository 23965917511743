.CustomInputWrapper {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 16px;
	padding: 16px;
	border-top: 1px solid var(--color-input-border-light);
	overflow: hidden;
	.input:nth-child(3) {
		grid-column: span 2;
	}
}
