.OtpVerify {
	&--formHeader {
		width: 100%;
		display: flex;
		align-items: center;
		padding: 10px;
		gap: 4px;
		.label-container {
			padding: unset;
			padding: 10px 10px 10px 0;
		}
	}
	&--otp {
		height: 100%;
	}
	&--form {
		width: 100%;
		display: flex;
		justify-content: center;
	}
	&--resend-btn {
		color: var(--color-primary-light);
	}
	&--btn {
		cursor: pointer;
		color: var(--color-primary-light);
	}
	&--resend-btn {
		color: var(--color-primary-light);
		font-size: var(--font-size-4);
	}
	&--otpDetail {
		color: #000;
		font-size: 18px;
		font-weight: 600;
	}
	&--disabled {
		cursor: not-allowed;
		color: var(--color-primary-light);
		opacity: 0.8;
	}

	&--edit {
		color: #000;
		display: flex;
		margin-bottom: 16px;
		text-align: center;
		&__editBtn {
			cursor: pointer;
			text-decoration: underline;
			width: 36px;
			color: var(--color-primary-light);
		}
		@media only screen and (max-width: 420px) {
			flex-direction: column;
			align-items: center;
		}
	}
	&--container {
		margin-bottom: 32px;
	}
	&--line {
		display: flex;
		gap: 10px;
		justify-content: center;
	}
	&--back-btn {
		align-items: center;
		border: none;
		border-radius: 6px;
		color: #fcfcfd;
		display: inline-flex;
		font-size: 16px;
		font-weight: 700;
		height: 48px;
		justify-content: center;
		line-height: 1;
		padding: 0 24px;
		text-align: center;
		transition: all 0.2s;
		width: 100%;
		background-color: #999999;
		cursor: pointer;
	}
	&--verifyButton {
		display: flex;
		justify-content: center;
		margin-top: 24px;
		align-items: center;
		width: 100%;
		.buttons {
			align-items: center;
			border: none;
			color: #fcfcfd;
			display: inline-flex;
			font-size: 16px;
			font-weight: 700;
			border-radius: 6px;
			height: 48px;
			justify-content: center;
			line-height: 1;
			padding: 0 24px;
			text-align: center;
			transition: all 0.2s;
			width: 100%;
		}
		&__submit {
			background: var(--color-primary-light);
		}
		&__back {
			background: rgb(153, 153, 153);
		}
	}
	&--otpInput {
		border: 1px solid #e6e8ec;
		border-radius: 10px;
		color: #000;
		font-weight: 500;
		height: 58px;
		text-align: center;
		width: 58px;
		font-size: 26px;
		background-color: hsla(0, 0%, 0%, 0.03);
		&:focus-visible {
			outline: 1px solid var(--color-primary-light) !important;
		}
	}
	&--submit-btn {
		@media screen and (max-width: 768px) {
			position: fixed;
			bottom: 0;
			right: 0;
			padding-bottom: 10px;
			background-color: var(--white);
		}
	}
	&--resend {
		display: flex;
		gap: 8px;
		@media only screen and (max-width: 320px) {
			flex-direction: column;
			align-items: center;
		}
	}
}
