.auto-fill-wrapper {
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
	&__label-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;

		&__label {
			font-weight: 500 !important;
			font-size: 16px !important;
			line-height: 16px !important;
			color: #747e99 !important;
		}
		span {
			color: red;
			margin-left: 4px;
			font-size: 15px;
		}
		&__locate-me-wrapper {
			display: flex;
			align-items: center;
			gap: 4px;
			color: var(--color-primary-light);
			cursor: pointer;
			opacity: 1;
			&:hover {
				opacity: 0.7;
			}

			i {
				font-size: 20px;
			}

			&__label {
				font-weight: 500;
				font-size: 12px;
				line-height: 16px;
			}
		}
	}
	&__input-wrapper {
		background: #f5f8ff;
		border: 1px solid #d7dff5;
		border-radius: 8px;
		padding: 0px 8px;
		display: flex;
		width: 100%;
		align-items: center;
		&:focus-within {
			border: 1px solid var(--color-primary-light);
			transition: 0.2s ease-in all;
			.search-icon {
				color: var(--color-primary-light);
				transition: 0.2s ease-in all;
			}
		}
		input {
			background-color: transparent;
			border: none;
			font-size: 14px;
			&:focus {
				border: none;
				outline: none;
			}
		}
		.search-icon {
			font-size: 24px;
			cursor: pointer;
			color: #747e99;
		}
	}
	&--manual-search {
		display: flex;
		gap: 4px;
		align-items: center;
	}
	&--address-visible {
		display: flex;
	}
	&--address-hide {
		display: none;
	}
}
