.ppl-screen-body {
	&__label-container {
		padding: var(--padding);
		width: 100%;
		display: flex;
		gap: 14px;
		align-items: center;
		padding-bottom: unset;
		&__text {
			font-weight: 600;
			font-size: var(--font-size-4);
			line-height: calc(var(--line-height-4) * 1.5rem);
			color: var(--color-text-90-light);
		}
	}

	&__body {
		&__list-wrapper {
			.list-item {
				padding: 8px;
			}
		}
		&__card-wrapper {
			width: 100%;
			.blank-image {
				max-height: 220px;
			}
		}
		&__title-wrapper {
			width: 100%;
			gap: 6px;
			margin-bottom: 24px;
			&__title {
				font-weight: 500;
				font-size: var(--font-size-4);
				line-height: calc(var(--line-height-2) * 1.75);
				color: var(--color-text-90-light);
				letter-spacing: 0;
			}
			&__subtitle {
				font-weight: 400;
				font-size: var(--font-size-3);
				line-height: 16px;
				color: var(--color-text-70-light);
				margin-top: 4px;
				margin-bottom: 1.5rem;
			}
			&__tips {
				display: flex;
				padding: 8px var(--padding);
				flex-direction: column;
				align-items: flex-start;
				gap: 4px;
				align-self: stretch;
				border-radius: calc(var(--border-radius) * 0.5);
				border: 1px dashed var(--color-light-orange);
				background: rgba(232, 138, 44, 0.12);
				margin-top: 24px;

				&__heading {
					color: var(--color-gray-medium-dark);
					font-size: var(--font-size-4);
					font-weight: 500;
					align-self: stretch;
					align-items: center;
					display: flex;
					gap: 4px;
					justify-content: flex-start;
					&__thunder-icon {
						color: #f38a20;
					}
				}

				&__steps {
					color: var(--color-davy-grey);
					font-size: var(--font-size-2);
					font-weight: 400;
					align-self: stretch;

					&__bullet {
						padding-left: 24px;
						font-size: var(--font-size-3);
					}
				}
			}
		}
		&--email-title-section {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.email-title {
				font-style: normal;
				font-weight: 600;
				font-size: var(--font-size-4);
				line-height: calc(var(--line-height-2) * 1.75);
				display: flex;
				align-items: center;
				color: var(--color-text-modal);
			}
			img {
				width: 14px;
				height: 14px;
			}
		}
		&--email-info {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.email-leftside {
				display: flex;
				align-items: center;
				gap: calc(var(--margin) * 0.5);
				.email-user-circle {
					width: 37px;
					height: 37px;
					background: var(--color-bg-copy);
					border-radius: 50%;
				}
				.email-info {
					display: flex;
					flex-direction: column;
					.email-info-top {
						display: flex;
						align-items: center;
						gap: calc(var(--margin) * 0.5);
						.sen-name {
							font-weight: 700;
							font-size: var(--font-size-3);
							line-height: calc(var(--line-height-2) * 1.25);
							color: var(-color-text-modal);
						}
						.date {
							font-style: normal;
							font-weight: 500;
							font-size: var(--font-size-2);
							line-height: calc(var(--line-height-2) * 0.75);
							color: var(--color-bg-copy);
						}
					}
				}
				.rec-name {
					font-style: normal;
					font-weight: 400;
					font-size: var(--font-size-3);
					line-height: var(--line-height-2);
					color: var(--color-bg-copy);
				}
			}
			.email-rightside {
				display: flex;
				align-items: center;
				gap: calc(var(--margin) * 1.5);
			}
		}
		&--email-body {
			display: flex;
			flex-direction: column;
			gap: var(--margin);
			.email-greetings {
				font-style: normal;
				font-weight: 600;
				font-size: var(--font-size-2);
				line-height: calc(var(--line-height-2) * 1.25);
				color: var(--color-header-label-dark);
			}
			.email-message {
				font-style: normal;
				font-weight: 400;
				font-size: var(--font-size-2);
				line-height: var(--line-height-2);
				color: var(-color-text-modal);
			}
			.email-message-btn {
				padding: calc(var(--padding) * 0.75) var(--padding);
				font-weight: 600;
				font-size: var(--font-size-2);
				line-height: var(--line-height-2);
				display: flex;
				align-items: center;

				color: var(--color-bg-100-light);
			}
			.email-message-footer {
				font-style: normal;
				font-weight: 400;
				font-size: var(--font-size-2);
				line-height: var(--line-height-2);
				display: flex;
				align-items: center;
			}
		}
		.bubble-line {
			width: 100%;
			position: relative;
			margin-top: calc(var(--margin) * 1.25);
			.bubble {
				background: #e9e9eb;
				border-radius: calc(var(--border-radius) * 1.25);
				padding: calc(var(--padding) * 0.5);
				position: relative;
				display: inline-block;
				width: auto;
				max-width: 274px;
				word-wrap: break-word;
				&::before {
					border-radius: calc(var(--border-radius) * 0.25);
					content: '';
					display: block;
					position: absolute;
					bottom: 0;
					left: -7px;
					z-index: -2;
				}
				&::after {
					border-radius: calc(var(--border-radius) * 0.25);
					content: '';
					display: block;
					position: absolute;
					border: 8px solid transparent;
					border-bottom-color: rgb(233, 233, 235);
					bottom: 1px;
					left: -5px;
				}
			}
		}
		&__button-wrapper {
			display: flex;
			flex-direction: column;
			gap: calc(var(--margin) * 0.5);
			width: 100%;
			margin-bottom: 80px;
			&__back-button {
				font-size: var(--font-size-5);
				color: var(--color-bg-100-dark);
			}
		}
		&__list-wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			&__header {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-bottom: 1px solid var(--color-input-border-light);
				padding-bottom: 8px;
				.add-bank-account {
					padding: 0;
					font-weight: 500;
					font-size: 12px;
					line-height: 16px;
					color: #3c65d6;
				}
			}
			.light-color-bold {
				font-weight: 600;
				font-size: 14px;
				line-height: 20px;
				color: #747e99;
			}
			.text-bold {
				font-weight: 600;
				font-size: 14px;
				line-height: 20px;
				color: #464c5c;
			}
			&__list-items {
				display: flex;
				align-items: center;
				gap: calc(var(--margin) * 0.5);
				width: 100%;
				height: 60px;
				border-bottom: 1px solid var(--color-input-border-light);
				&__empty_icons {
					width: 40px;
					height: 40px;
					background: #e6e6e6;
					border-radius: 8px;
				}
				.left-side {
					flex: 1;
				}
				.right-side {
					text-align: right;
				}
				.account-details {
					display: flex;
					flex-direction: column;
					gap: 4px;
					&__normal {
						font-weight: 400;
						font-size: 12px;
						line-height: 16px;
						color: #747e99;
					}
					&__bold {
						font-weight: 600;
						font-size: 14px;
						line-height: 20px;
						color: #464c5c;
					}
				}
			}
			.list-item {
				display: flex;
				align-items: center;
				gap: calc(var(--margin) * 0.5);
				width: 100%;
				height: 60px;
				border-bottom: 1px solid var(--color-input-border-light);

				.list-text {
					flex-grow: 1;
				}
				&:hover {
					background-color: var(--color-hover-light-100);
					transition: 0.3s ease-in all;
					border-radius: 8px;
					cursor: pointer;
				}
			}

			&__footer {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}
		&__input-wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			gap: var(--margin);
			&__input-list {
				width: 100%;
				display: flex;
				flex-direction: column;
				gap: 4px;
				.cursor-pointer {
					cursor: pointer;
				}
				.select-component {
					display: flex;
					flex-direction: column;
					gap: 4px;
					.select-label {
						font-weight: 500;
						font-size: 12px;
						line-height: 16px;
						color: #5d657a;
					}
				}
			}
		}
	}
}
.empty-wrapper {
	display: grid;
	place-items: center;
	height: 100%;
	text-align: center;
	img {
		min-width: 240px;
		min-height: 148px;
		margin: calc(var(--margin) * 1.5);
	}
}

.center-wrapper {
	display: grid;
	place-items: center;
	height: 100%;
	&__card {
		margin: var(--margin);
		box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
		background: var(--color-cultured);
		border-radius: calc(var(--border-radius) * 0.25);
		padding: var(--padding);
		&__details {
			&__header {
				display: flex;
				justify-content: space-between;
				margin-left: calc(var(--margin) * 1.25);
				margin-bottom: var(--margin);
				img {
					width: 30px;
					margin-top: calc(var(--margin) * 0.5);
				}
				.icon {
					display: flex;
					justify-content: flex-end;
					font-size: var(--font-size-6);
				}
			}
			&__lists {
				display: flex;
				flex-direction: column;
				gap: var(--margin);
				.close-btn {
					width: 100%;
					display: flex;
					justify-content: flex-end;
					font-size: var(--font-size-5);
					border: 2px solid var(--color-black);
					height: 25px;
				}
				&__list-item {
					display: flex;
					gap: calc(var(--margin) * 0.5);
					&__content {
						.title {
							font-size: var(--font-size-4);
							font-weight: bold;
							color: var(--color-darkgunmetal);
						}
						.desc {
							font-size: var(--font-size-3);
							color: var(--color-davy-grey);
						}
					}
				}
				.black-btn {
					color: var(--color-bg-100-light);
					background-color: var(--color-black);
					padding: calc(var(--padding) * 0.5);
				}
			}
		}
	}
}

.filled-card {
	background: var(--color-bg-100-light);
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	overflow: hidden;
	height: 100% !important;

	@media screen and (max-width: 768px) {
		margin-bottom: 24px;
	}

	@media screen and (max-width: 360px) {
		width: 320px;
		height: 200px;
	}
	.id-card {
		border: 1px dashed var(--color-lavender-gray);
		border-radius: 12px;
		width: 100%;
		min-height: 200px;
	}
	.skeleton-img-loader {
		width: 417px;
		.skelton-loader-wrapper__content {
			border-radius: 12px;
		}
	}
	&__selfie_img {
		width: 100%;
		height: 100%;
		object-fit: unset;
		transform: rotate(-90deg);
	}

	&__card-image {
		border: 1px solid hsla(0, 0%, 0%, 0.12);
		border-radius: 12px;
		height: 269px;
		width: 100%;
		object-fit: contain;
	}
	&__webcam {
		position: absolute;
		width: 100%;
		height: 100%;
		> video {
			width: 100%;
			height: 100%;
		}
	}
	&__center {
		display: grid;
		place-items: center;
		flex-grow: 1;
		width: 100%;
		#myImage {
			border: 1px dashed var(--color-lavender-gray);
		}
		img {
			top: 0px;
			width: 100%;
			min-width: 400px;
			// min-height: 270px;
		}
	}
	&__bottom {
		@media screen and (max-width: 768px) {
			position: fixed;
			padding: 20px 0;
			//TODO avinashSatschel need to add left0
			padding-right: 25px;
			bottom: 0;
			background-color: var(--color-white);
		}
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 16px;
		gap: calc(var(--margin) * 0.8);
		@media screen and (max-width: 340px) {
			flex-direction: column;

			.border-right {
				display: none;
			}
		}
		.button {
			padding: calc(var(--padding) * 0.5);
			background: rgba(108, 112, 122, 0.12);
			border-radius: calc(var(--border-radius) * 0.25);
			margin-left: calc(var(--margin) * 0.8);
			margin-right: calc(var(--margin) * 0.5);
		}
		.border-right {
			height: 25px;
			border: 0.25px solid var(--color-lavender-gray);
		}
		.btn-icon-wrapper {
			display: flex;
			align-items: center;
			gap: calc(var(--margin) * 0.5);
			color: white;
		}
	}

	.color-red {
		color: #f55353;
	}
	.image-action-wrapper {
		position: absolute;
		top: 8px;
		right: 8px;
		display: flex;
		flex-direction: column;
		gap: calc(var(--margin) * 0.5);
		&__btn {
			.button {
				all: unset;
			}
			cursor: pointer;
			background: #ffffff;
			border: 0.5px solid var(--color-tart-orange);
			height: 32px;
			font-size: var(--font-size-2);
			border-radius: calc(var(--border-radius) * 0.25);
			color: var(--color-tart-orange);
			display: flex;
			align-items: center;
			gap: 8px;
			padding: 0px 8px;
			.action-icon {
				font-size: var(--font-size-3);
			}
		}
	}
}

.ppl-screen-body__body__button-wrapper__Back-btn {
	display: flex;
	justify-content: flex-start;
	// margin-top: 16px;
	.button__block {
		// width: max-content;
		width: 120px;
		height: 52px;
		background: transparent;
	}
	// @media screen and (min-width: 500px) {
	//   .button__block {
	//     width: 100px;
	//   }
	// }
}

.camera {
	background-image: url("data:image/svg+xml,%0A%3Csvg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.55216 1.33333L4.21883 2.66667H1.66683V10.6667H12.3335V2.66667H9.7815L8.44816 1.33333H5.55216ZM5.00016 0H9.00016L10.3335 1.33333H13.0002C13.177 1.33333 13.3465 1.40357 13.4716 1.5286C13.5966 1.65362 13.6668 1.82319 13.6668 2V11.3333C13.6668 11.5101 13.5966 11.6797 13.4716 11.8047C13.3465 11.9298 13.177 12 13.0002 12H1.00016C0.823352 12 0.653782 11.9298 0.528758 11.8047C0.403734 11.6797 0.333496 11.5101 0.333496 11.3333V2C0.333496 1.82319 0.403734 1.65362 0.528758 1.5286C0.653782 1.40357 0.823352 1.33333 1.00016 1.33333H3.66683L5.00016 0ZM7.00016 10C6.0277 10 5.09507 9.61369 4.40744 8.92606C3.7198 8.23843 3.3335 7.30579 3.3335 6.33333C3.3335 5.36087 3.7198 4.42824 4.40744 3.74061C5.09507 3.05297 6.0277 2.66667 7.00016 2.66667C7.97262 2.66667 8.90525 3.05297 9.59289 3.74061C10.2805 4.42824 10.6668 5.36087 10.6668 6.33333C10.6668 7.30579 10.2805 8.23843 9.59289 8.92606C8.90525 9.61369 7.97262 10 7.00016 10ZM7.00016 8.66667C7.619 8.66667 8.21249 8.42083 8.65008 7.98325C9.08766 7.54566 9.3335 6.95217 9.3335 6.33333C9.3335 5.7145 9.08766 5.121 8.65008 4.68342C8.21249 4.24583 7.619 4 7.00016 4C6.38132 4 5.78783 4.24583 5.35025 4.68342C4.91266 5.121 4.66683 5.7145 4.66683 6.33333C4.66683 6.95217 4.91266 7.54566 5.35025 7.98325C5.78783 8.42083 6.38132 8.66667 7.00016 8.66667Z' fill='%235D657A'/%3E%3C/svg%3E%0A");
	background-repeat: no-repeat no-repeat;
	background-position: center center;
	background-size: cover;
	width: 13px;
	height: 12px;
}

.retake-icon {
	background-image: url("data:image/svg+xml,%0A%3Csvg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.55216 1.33333L4.21883 2.66667H1.66683V10.6667H12.3335V2.66667H9.7815L8.44816 1.33333H5.55216ZM5.00016 0H9.00016L10.3335 1.33333H13.0002C13.177 1.33333 13.3465 1.40357 13.4716 1.5286C13.5966 1.65362 13.6668 1.82319 13.6668 2V11.3333C13.6668 11.5101 13.5966 11.6797 13.4716 11.8047C13.3465 11.9298 13.177 12 13.0002 12H1.00016C0.823352 12 0.653782 11.9298 0.528758 11.8047C0.403734 11.6797 0.333496 11.5101 0.333496 11.3333V2C0.333496 1.82319 0.403734 1.65362 0.528758 1.5286C0.653782 1.40357 0.823352 1.33333 1.00016 1.33333H3.66683L5.00016 0ZM7.00016 10C6.0277 10 5.09507 9.61369 4.40744 8.92606C3.7198 8.23843 3.3335 7.30579 3.3335 6.33333C3.3335 5.36087 3.7198 4.42824 4.40744 3.74061C5.09507 3.05297 6.0277 2.66667 7.00016 2.66667C7.97262 2.66667 8.90525 3.05297 9.59289 3.74061C10.2805 4.42824 10.6668 5.36087 10.6668 6.33333C10.6668 7.30579 10.2805 8.23843 9.59289 8.92606C8.90525 9.61369 7.97262 10 7.00016 10ZM7.00016 8.66667C7.619 8.66667 8.21249 8.42083 8.65008 7.98325C9.08766 7.54566 9.3335 6.95217 9.3335 6.33333C9.3335 5.7145 9.08766 5.121 8.65008 4.68342C8.21249 4.24583 7.619 4 7.00016 4C6.38132 4 5.78783 4.24583 5.35025 4.68342C4.91266 5.121 4.66683 5.7145 4.66683 6.33333C4.66683 6.95217 4.91266 7.54566 5.35025 7.98325C5.78783 8.42083 6.38132 8.66667 7.00016 8.66667Z' fill='%235D657A'/%3E%3C/svg%3E%0A");
	background-repeat: no-repeat no-repeat;
	background-position: center center;
	background-size: cover;
	width: 13px;
	height: 12px;
	color: #f55353;
}
.upload-wrapper {
	display: flex;
	align-items: center;
	border-radius: calc(var(--border-radius) * 0.25);
	width: 100%;
	justify-content: space-around;
	flex-direction: row;
	padding: 12px 16px;
	background-color: var(--color-primary-light);
	cursor: pointer;
	min-width: 160px;
	max-width: 400px;
}
.disable-btn-icon-wrappers {
	pointer-events: none;
	opacity: 0.4;
	cursor: not-allowed;
}

.camera-btn-icon-wrappers {
	display: flex;
	align-items: center;
	background: var(--color-white-border);
	border-radius: calc(var(--border-radius) * 0.25);
	width: 100%;
	justify-content: space-around;
	flex-direction: row;
	padding: 12px 16px;
	cursor: pointer;
}

.upload-text {
	font-style: normal;
	font-weight: 500;
	font-size: var(--font-size-2);
	line-height: var(--line-height-2);
	color: var(--color-white);
}

label[for='Use My Camera__frontImage'] .upload-text,
label[for='Use My Camera__backImage'] .upload-text {
	color: #000000 !important;
}

.camera-btn-text {
	font-style: normal;
	font-weight: 500;
	font-size: var(--font-size-2);
	line-height: var(--line-height-2);
	color: black;
}

.empty-screen-error {
	display: grid;
	place-items: center;
	height: 100%;
	width: 100%;
	&__inner {
		width: 90%;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: calc(var(--margin) * 2);
		&__text {
			font-size: var(--font-size-4);
			text-align: center;
			span {
				color: var(--color-primary-light);
			}
		}
		&__img {
			width: 300px;
		}
		.mt-2 {
			margin-top: var(--margin);
		}
	}
}

.loader-wrapper {
	display: grid;
	place-items: center;
	height: 100%;
	&__inner {
		display: flex;
		flex-direction: column;
		gap: calc(var(--margin) * 2);
	}
}

.iframe-wrapper {
	width: 100%;
	height: 100%;
	overflow: hidden;
	&__iframe-tag {
		height: 100%;
		width: 100%;
		border: none;
	}
}
.qr-code-screen {
	width: 100%;
	height: 100vh;
	display: grid;
	place-items: center;
	background: var(--color-bg-90-light);
	&__card {
		padding: calc(var(--padding) * 3) calc(var(--padding) * 1.5);
		max-width: 488px;
		height: 636px;
		background: var(--color-bg-100-light);
		box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
		border-radius: var(--border-radius);
		&__inner {
			display: flex;
			flex-direction: column;
			gap: calc(var(--margin) * 3);
			&__logo {
				width: 192px;
				height: 56px;
			}
			&__text {
				display: flex;
				flex-direction: column;
				gap: calc(var(--margin) * 0.5);
				&__bold {
					font-weight: 700;
					font-size: var(--font-size-5);
					line-height: var(--line-height-5);
					color: var(--color-text-100-light);
				}
				&__normal {
					font-weight: 500;
					font-size: var(--font-size-3);
					line-height: var(--line-height-3);
					color: var(--color-text-60-light);
				}
			}
			&__qr-code {
				display: flex;
				justify-content: center;
				&__details {
					display: grid;
					place-items: center;
					gap: var(--margin);
					&__no-required-text {
						font-weight: 500;
						font-size: var(--font-size-3);
						line-height: var(--line-height-3);
						color: var(--color-text-60-light);
					}
				}
			}
			#qr-code-image {
				width: 100% !important;
				height: 100% !important;
				display: grid;
				place-items: center;
				canvas {
					min-width: 250px !important;
					min-height: 250px !important;
				}
			}
		}
	}
}
.selfie-text-color {
	background: #f55353;
}
.selfie-footer-btn {
	display: flex;
	flex-direction: column;
	gap: 8px;
	label {
		font-size: 14px;
	}
}
.redirect-links {
	text-decoration: none;
}

.retake-selfie {
	background: var(--color-bg-100-light);
	border-radius: 4px;
	padding: 0px 8px;
	height: 32px;
	display: grid;
	place-items: center;
	.btn-icon-wrapper {
		display: flex;
		gap: 8px;
		font-size: var(--font-size-3);
		font-weight: 500;
		align-items: center;
		line-height: 20px;
		.upload-text {
			color: #f55353;
		}
	}
}
.filled-seflie-button-wrapper {
	position: absolute !important;
	bottom: 0px;
	padding: 0 !important;
}

.retake-btn {
	&::before {
		content: '';
		background-image: url("data:image/svg+xml,%0A%3Csvg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.55216 1.33333L4.21883 2.66667H1.66683V10.6667H12.3335V2.66667H9.7815L8.44816 1.33333H5.55216ZM5.00016 0H9.00016L10.3335 1.33333H13.0002C13.177 1.33333 13.3465 1.40357 13.4716 1.5286C13.5966 1.65362 13.6668 1.82319 13.6668 2V11.3333C13.6668 11.5101 13.5966 11.6797 13.4716 11.8047C13.3465 11.9298 13.177 12 13.0002 12H1.00016C0.823352 12 0.653782 11.9298 0.528758 11.8047C0.403734 11.6797 0.333496 11.5101 0.333496 11.3333V2C0.333496 1.82319 0.403734 1.65362 0.528758 1.5286C0.653782 1.40357 0.823352 1.33333 1.00016 1.33333H3.66683L5.00016 0ZM7.00016 10C6.0277 10 5.09507 9.61369 4.40744 8.92606C3.7198 8.23843 3.3335 7.30579 3.3335 6.33333C3.3335 5.36087 3.7198 4.42824 4.40744 3.74061C5.09507 3.05297 6.0277 2.66667 7.00016 2.66667C7.97262 2.66667 8.90525 3.05297 9.59289 3.74061C10.2805 4.42824 10.6668 5.36087 10.6668 6.33333C10.6668 7.30579 10.2805 8.23843 9.59289 8.92606C8.90525 9.61369 7.97262 10 7.00016 10ZM7.00016 8.66667C7.619 8.66667 8.21249 8.42083 8.65008 7.98325C9.08766 7.54566 9.3335 6.95217 9.3335 6.33333C9.3335 5.7145 9.08766 5.121 8.65008 4.68342C8.21249 4.24583 7.619 4 7.00016 4C6.38132 4 5.78783 4.24583 5.35025 4.68342C4.91266 5.121 4.66683 5.7145 4.66683 6.33333C4.66683 6.95217 4.91266 7.54566 5.35025 7.98325C5.78783 8.42083 6.38132 8.66667 7.00016 8.66667Z' fill='%23F54545'/%3E%3C/svg%3E%0A");
		background-repeat: no-repeat no-repeat;
		background-position: center center;
		background-size: cover;
		width: 13px;
		height: 12px;
		color: var(--color-tart-orange);
	}
}

.v-center {
	display: grid;
	place-items: 'center';
	height: 100vh;
}
.gap-16 {
	gap: 16px;
}
.p-16 {
	padding: 16px;
}

.d-none {
	display: none;
}
.d-block {
	display: block;
}
.animate-in-2 {
	animation: fade-in 2s;
}
.animate-out-2 {
	animation: fade-out 2s;
}
@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fade-out {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
.upload-document-wrapper {
	background-color: var(--color-bg-90-light);
	border: 1px dashed #cad1e6;
	border-radius: 8px;
	width: 100%;
	min-height: 200px;
	display: grid;
	place-items: center;
	&__inner {
		&__details {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 8px;
			label {
				font-weight: 500;
				font-size: 14px;
				line-height: 20px;
				color: #464c5c;
				span {
					color: var(--color-input-border-focus-light);
				}
			}
			.info {
				font-weight: 500;
				font-size: 12px;
				line-height: 20px;
				color: #8c97b8;
			}
		}
	}
}
.mt-20 {
	margin-top: 20px;
}

.uploaded-lists {
	height: 50px;
	width: 100%;
	border-bottom: 1px solid var(--color-input-border-light);
	display: flex;
	&__items {
		display: flex;
		align-items: center;
		width: 100%;
		gap: 16px;
		padding: 0 calc(var(--padding) * 0.5);
		&__listname {
			flex: 1;
			font-weight: 500;
			font-size: 14px;
			line-height: 20px;
			color: #2f323d;
			overflow: hidden;
		}
	}
}
.note-info {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	color: #747e99;
	background: rgba(239, 182, 67, 0.06);
	border: 1px solid #f5af45;
	border-radius: 8px;
	padding: 12px;
	display: flex;
	align-items: flex-start;
	gap: 12px;
	@media screen and (min-width: 900px) {
		display: none;
	}
	&__icon {
		.warning-icon {
			font-size: 24px;
			color: #f5af45;
		}
	}
	&__content {
		display: flex;
		flex-direction: column;
		gap: 4px;
	}
	.note-text {
		font-weight: 600;
		color: var(--color-black);
	}
	.click-getinfo-btn {
		color: #1445c9;
		font-size: 14px;
		font-weight: 600;
		display: flex;
		align-items: center;
		gap: 8px;
		justify-content: flex-end;
	}
}
.w-100 {
	width: 100%;
}
.modal-cancel-btn {
	background: rgba(255, 255, 255, 0.12);
	border-radius: 32px;
	color: #fff;
	min-width: 67px;
}

.modal-wrapper {
	background: rgba(0, 0, 0, 0.72);
	backdrop-filter: blur(2px);
	height: 100%;
	position: fixed;
	width: 100%;
	display: grid;
	place-items: center;
	padding: 16px;
	margin: -180px 0px 0px 0;
	z-index: 999;
}
.wait-message {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #ffffff;
}

.sign-aggrement__container {
	width: 100%;
	height: 100%;
}

//common css
.d-flex {
	display: flex;
	align-items: center;
}
.gp-8 {
	gap: 8;
}
.gp-16 {
	gap: 8;
}
.gp-24 {
	gap: 8;
}
.direction-column {
	flex-direction: column;
}
.fs-12 {
	font-size: 12;
}
.fs-14 {
	font-size: 14;
}
.fs-16 {
	font-size: 16;
}

.capture-btn {
	display: none;
	> button {
		height: 52px;
		width: 120px;
		border: 1px solid;
		padding: 4px 6px;
		font-size: 10px;
		border-radius: 8px;
		background: var(--color-primary-light, #0051cc) !important;
	}
}

.capture-vid {
	display: none !important;
	position: absolute;
	top: 0px;
	right: 0px;
}

.capture-vid-active {
	height: 460px;
	object-fit: initial;
}

.capture-btn-active {
	> button {
		width: 355px;
		height: 54px;
		background: var(--color-primary-light, #0051cc) !important;
	}
}

.verification_capture-button-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 20px;
}

.id-card-hide {
	display: none;
}

.filled-card-cameraScreenInactive {
	width: 100%;
	@media screen and (min-width: 769px) {
		width: 400px;
	}
}

.full-screen-modal {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white; /* Add your desired background color */
	overflow: auto;
	z-index: 9999;
}

.image_capture__modal {
	inset: 0px !important;
	padding: 0px !important;
}

.modal__capture-btn {
	display: flex !important;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100%;
}

.capture__code {
	position: absolute;
	bottom: 10px;
}

.back__btn {
	position: absolute;
	top: 70px;
	cursor: pointer;
	padding: 10px;
	color: #fff;
	font-size: 16px;
	i {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.Image_capture--back-btn {
	z-index: 1;
	position: absolute;
}

@media (max-width: 768px) {
	.button-container {
		flex-direction: column; /* On smaller screens, buttons stack in a column */
	}
	.ppl-screen-body__body__button-wrapper__Back-btn .button__block {
		width: 355px;
		height: 54px;
	}
	.ppl-screen-body__body__button-wrapper__Back-btn_margin {
		margin-top: 20px;
	}
	.camera-btn-icon-wrappers {
		width: 100%;
		white-space: nowrap;
	}

	.filled-card-active {
		width: auto;
		height: auto;
	}
	.upload-wrapper {
		width: 100%;
		white-space: nowrap;
	}
}

@media screen and (max-width: 768px) {
	.verification__body_wrapper {
		.ppl-screen-body {
			overflow: unset !important;
			&__body {
				position: relative;
				overflow: unset !important;
			}
		}
	}
}

@media screen and (min-width: 500px) {
	.verification__body_wrapper {
		.ppl-screen-body {
			overflow: unset;
		}
	}
}

@media screen and (max-width: 450px) {
	.ppl-screen-body__body__card-wrapper {
		width: 100%;
	}
	.filled-card {
		width: 100%;
	}
	.filled-card__center {
		img {
			min-height: unset !important;
			min-width: unset !important;
		}
	}

	.skeleton-img-loader {
		width: 100% !important;
	}
}
