.radio-card {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 8px;
	&__inner {
		border: 1px solid var(--color-input-border-light);
		border-radius: 8px;
	}
}
.card-content {
	font-size: 14px;
	margin-top: -3px;
}

input[type='radio'] {
	min-width: 20px;
	min-height: 16px;
	scale: 1.1 !important;
	transform: scale(1.1) !important;
}

label.radio-card {
	cursor: pointer;
	.card-content-wrapper {
		background: #fff;
		border-radius: 5px;
		width: 100%;
		padding: 15px;
		display: flex;
		gap: 8px !important;
		border-radius: 8px;
		align-items: flex-start !important;
	}
}
