$base: 38px;

.breadcrumb {
  display: flex;
  width: 100%;
  background: #d8dbe6;
  font-size: 10px;
  height: 38px;
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
}

.breadcrumb::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.breadcrumb .breadcrumb-list {
  position: relative;
  display: flex;
  flex-grow: 1;
  text-decoration: none;
  margin: auto;
  height: 100%;
  padding-left: $base / 1.5;
  padding-right: 0;
  color: #666;
  font-weight: 600;
}

.breadcrumb .breadcrumb-list.completed {
  background: #33b87a;
  color: white;
}

.breadcrumb .breadcrumb-list.completed:after {
  background: #33b87a;
  color: white;
}

.breadcrumb .breadcrumb-list.pending {
  background: #d8dbe6;
  color: #747e99;
}

.breadcrumb .breadcrumb-list.pending:after {
  background: #d8dbe6;
  color: #747e99;
}

.breadcrumb .breadcrumb-list:first-child {
  padding-left: $base / 2.5;
}

.breadcrumb .breadcrumb-list:last-child {
  padding-right: $base / 2.5;
}

.breadcrumb .breadcrumb-list:after {
  content: "";
  position: absolute;
  display: inline-block;
  width: $base * 1.5;
  height: $base * 1.5;
  top: 0;
  right: $base / 1.35 * -1;
  background-color: #ddd;
  border-top-right-radius: 5px;
  transform: scale(0.707) rotate(45deg);
  box-shadow: 2px -2px #ffffff;
  z-index: 1;
}

.breadcrumb .breadcrumb-list:last-child:after {
  content: none;
}

.breadcrumb__inner {
  display: flex;
  flex-direction: column;
  margin: auto;
  z-index: 2;
}

.breadcrumb .breadcrumb-list.active {
  background: #3c65d6;
  color: white;
}

.breadcrumb .breadcrumb-list.active:after {
  background: #3c65d6;
  color: white;
}
.breadcrumb .breadcrumb-list:after {
  content: "";
  width: $base * 1;
  height: $base * 1;
  right: $base / 2 * -1;
  transform: scale(0.707) rotate(45deg);
}
