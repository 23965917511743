.hider {
  position: absolute;
  // top: 0;
  width: 100%;
  height: 52px;
  background: var(--color-bg-100-light);
}
.relative {
  position: relative;
}

.kyc-loader-wrapper {
  position: absolute;
  inset: 0;
  display: grid;
  place-items: center;
}
