.kyb-list {
	display: grid;
	gap: 16px;
	grid-column: span 2 / span 2;
	&__card-wrapper {
		border: 1px solid #ccc;
		padding: 8px 8px;
		position: relative;
		border-radius: 8px;
		transition: box-shadow 0.3s;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
		cursor: pointer;
	}
	&__card-wrapper:hover {
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	}
	&__radio-button {
		position: absolute;
		top: 10px;
		right: 10px;
		width: 20px;
		height: 20px;
		cursor: pointer;
		&:checked {
			accent-color: var(--color-primary-light);
		}
	}
	&__intials {
		width: 44px;
		height: 44px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 4px;
		border: 1px solid #dfe3ec;
	}
	&__details-wrapper {
		display: flex;
		align-items: center;
	}
	&__address-wrapper {
		display: flex;
		align-items: flex-start;
		font-size: 12px;
		font-weight: 500;
		line-height: 16px;
		text-align: left;
		color: #8d93a5;
		gap: 4px;
	}
	&__name-wrapper {
		padding: 0px 8px;
	}
	&__name {
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
		text-align: left;
		text-transform: capitalize;
	}
	&__loader-title {
		padding-top: 24px;
		font-size: 24px;
		font-weight: 600;
		line-height: 24px;
		text-align: center;
		color: var(--color-text-light-90);
	}
	&__loader-desp {
		font-size: 14px;
		font-weight: 500;
		line-height: 24px;
		text-align: center;
		color: var(--color-text-light-70);
	}
	&__ein {
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
		text-align: left;
		color: #8d93a5;
	}
	&__ein-value {
		font-size: 12px;
		font-weight: 600;
		line-height: 16px;
		text-align: left;
		color: black;
	}
	&__card-desp {
		font-size: 12px;
		font-weight: 500;
		line-height: 16px;
		text-align: left;
		color: #8d93a5;
	}
	&__address {
		font-size: 12px;
		font-weight: 500;
		line-height: 16px;
		text-align: left;
		color: #8d93a5;
		width: 15%;
	}
	&__collen {
		font-size: 12px;
		font-weight: 500;
		line-height: 16px;
		text-align: left;
		color: #8d93a5;
		margin-right: 8px;
	}
	&__dot-green {
		background: #389f75;
		min-width: 10px;
		min-height: 10px;
		border-radius: 50%;
		margin-right: 4px;
	}
	&__dot-yellow {
		background: #e88a2c;
		min-width: 10px;
		min-height: 10px;
		border-radius: 50%;
		margin-right: 4px;
	}
	&__dot-red {
		background: #d32222;
		min-width: 10px;
		min-height: 10px;
		border-radius: 50%;
		margin-right: 4px;
	}
	&__address-value {
		color: black;
		font-weight: 500;
		display: flex;
		align-items: center;
		text-transform: capitalize;
	}
	&__loader-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 8px;
		font-size: medium;
		text-overflow: ellipsis;
	}
	&__loader-box {
		min-width: 40px;
		overflow: hidden;
	}
}
.kyb-loader {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	text-align: center;
	height: 70vh;
	gap: 16px;
	grid-column: span 2 / span 2;
	.button__large {
		height: 48px;
	}
}
.the-kyb-button {
	width: 100% !important;
	min-width: 110px;
	display: flex;
	justify-content: center;
}

@media only screen and (max-width: 420px) {
	.kyb-list__address {
		min-width: 30%;
	}
}

.input-fields {
	.css-4ljt47-MenuList,
	.css-11unzgr {
		max-height: 198px !important;
		overflow: auto;
		border-radius: 8px;
	}
}

.kyb-members {
	display: flex;
	flex-direction: column;
	gap: 16px;
	grid-column: span 2 / span 2;

	.DateCalender {
		width: 100%;
		height: 52px;
		margin-bottom: 28px;
	}

	&__card {
		width: 100%;
		display: flex;
		flex-direction: column;
		border: 1px solid #dfe3ec;
		border-radius: 4px;
		cursor: pointer;
		position: relative;
		transition: all 0.6s;

		&_active {
			border: 1px solid var(--color-primary-light);
			transition: all 1s;
		}

		&_error {
			border: 1px solid #cc0000;
			transition: all 1s;
		}

		&__kyc-aml-wrapper {
			display: flex;
			flex-direction: column;
			gap: 16px;
			padding: 16px;
			&__card-header {
				display: flex;
				align-items: center;
				gap: 8px;
			}
			&__text {
				font-size: 16px;
				font-weight: 600;
				line-height: 24px;
				color: #1d1e21;
			}
			&__add-icon {
				font-size: 24px;
				color: var(--color-primary-light);
			}
		}

		&__wrapper {
			display: flex;
			align-items: center;
			gap: 8px;
			padding: 16px;
		}
		&__input-wrapper {
			display: flex;
			flex-direction: column;
			// align-items: center;
			gap: 8px;
			padding: 16px;
			@media screen and (min-width: 786px) {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 20px;
			}
		}

		&__para {
			width: 48px;
			height: 48px;
			border-radius: 50%;
			background: #f4f5f5;
			font-size: 14px;
			font-weight: 600;
			line-height: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #1d1e21;
		}

		&__initial {
			display: flex;
			flex-direction: column;
			gap: 4px;

			&__text {
				font-size: 16px;
				font-weight: 500;
				line-height: 24px;
				color: #1d1e21;
			}

			&__designation {
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				color: #6c7489;
			}
		}
	}

	.mobile-input__container {
		width: 100%;
	}
}

.select-subsidiary {
	&__component-header {
		flex-shrink: 0;
		position: sticky;
		top: 15px;
		background-color: #ffffff;
		z-index: 10;
		grid-column: span 2 / span 2;
		margin-bottom: 4px;
	}

	&__cards-body {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		align-items: center;
		height: calc(100vh - 334px);
		grid-column: span 2 / span 2;

		&__cards-component {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 10px;
			overflow: auto;
			margin-bottom: 20px;
		}
	}

	&__wrapper {
		width: 100%;
		padding: 8px 12px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		border-radius: 8px;
		border: 1px solid var(--color-border-light-100);
	}

	&__header {
		display: flex;
		gap: 12px;
		align-items: center;

		&__title {
			font-size: 16px;
			font-weight: 600;
			line-height: 24px;
		}

		&__checkbox {
			width: 18px;
			height: 18px;
			background: #82848a;
			cursor: pointer;
		}
	}

	&__content {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 8px;

		&__wrapper {
			width: 100%;
			display: flex;
			gap: 8px;
			align-items: baseline;
			justify-content: space-between;
		}

		&__label {
			width: 50px;
			font-size: 14px;
			font-weight: 500;
			color: var(--color-text-light-80);
		}
		&__colon {
			margin-left: 10px;
			margin-right: 2px;
		}

		&__value {
			width: 89%;
			font-size: 14px;
			font-weight: 500;
			color: var(--color-text-light-80);
			color: #1d1e21;
		}
	}

	&__search-wrapper {
		width: 100%;
		height: 45px;
		padding: 6px;
		display: flex;
		align-items: center;
		border: 1px solid var(--color-border-light-100);
		border-radius: 5px;

		&__search-icon {
			padding-right: 6px;
			display: flex;
			align-items: center;
			border: none;
			cursor: pointer;
		}

		&__search-bar {
			width: 100%;
			padding: 6px;
			font-size: 16px;
			border: none;

			&:focus-visible {
				outline: none !important;
			}
		}
	}

	&__loader {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		text-align: center;
		height: 51vh;
		gap: 16px;

		&__title {
			padding-top: 14px;
			font-size: 24px;
			font-weight: 600;
			line-height: 24px;
			text-align: center;
			color: var(--color-text-light-90);
		}

		&__desp {
			font-size: 14px;
			font-weight: 500;
			line-height: 24px;
			text-align: center;
			color: var(--color-text-light-70);
		}

		&__no-data-icon {
			color: rgb(243, 138, 32);
			font-size: 60px;
		}

		&__no-data-desp {
			font-size: 19px;
			font-weight: 500;
			color: var(--color-text-light-70);
			line-height: 24px;
			text-align: center;
		}
	}
}

// kyb responsive and button sticky css
.kyb-responsive {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	overflow: auto;
	margin-top: 10px;

	&__overflow {
		width: 100%;
		overflow: auto;
	}
	&__button-sticky {
		position: sticky;
		bottom: 12px;
		width: 100%;
		background-color: white;
	}
}

// kyb search bar css
.kyb-search-input {
	&__search-wrapper {
		width: 100%;
		height: 45px;
		padding: 6px;
		display: flex;
		align-items: center;
		border: 1px solid var(--color-border-light-100);
		border-radius: 5px;
		transition: box-shadow 0.6s;

		&:focus-within {
			box-shadow: 0 0 0 1px #eee2e2;
		}
		&__search-icon {
			padding-right: 6px;
			display: flex;
			align-items: center;
			border: none;
			cursor: pointer;
		}

		&__search-bar {
			width: 100%;
			padding: 6px;
			font-size: 16px;
			border: none;

			&:focus-visible {
				outline: none !important;
			}
		}
	}
}
.kyc-aml-members {
	&__toggle-wrapper {
		grid-column: span 2 / span 2;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		&__title-container {
			display: flex;
			flex-direction: column;

			&__title {
				font-size: 14px;
				font-weight: 600;
				line-height: 20px;
			}

			&__description {
				font-size: 12px;
				line-height: 16px;
				color: var(--color-text-light-70);
			}
		}
	}
}
