.investor-details {
	&__form-wrapper {
		display: flex;
		flex-direction: column;
		gap: 20px;
		.input__text-field {
			background-color: #f9f9f9;
		}
		label {
			width: 100%;
			display: flex;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}
.sd-question__content {
	> div {
		pointer-events: unset !important;
	}
}
.sd-question__content {
	> div.investor_form--read-only {
		pointer-events: none !important;
		cursor: not-allowed;
	}
}
.investor-table {
	display: flex;
	gap: 8px;
	flex-direction: column;
	overflow-x: scroll;
	min-height: 300px;
	padding-bottom: 20px;
	&__wraper {
		overflow: scroll;
		pointer-events: unset !important;
		div {
			pointer-events: unset !important;
		}
	}
	&__header {
		background-color: lightgray;
		padding: 4px 0;
		display: flex;
		background-color: var(--color-white);
		font-weight: 400;
		font-size: var(--font-size-3);
	}
	&__body {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	&__heading {
		padding: 4px;
		flex-grow: 1;
		min-width: 250px;
		width: 250px;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: center;
		&--country {
			min-width: 150px;
			width: 150px;
		}
		&--first-name,
		&--last-name {
			min-width: 200px;
			width: 200px;
		}
		&--email {
			min-width: 250px;
			width: 250px;
		}
		&--phone {
			min-width: 180px;
			width: 180px;
		}
		&--shares {
			min-width: 150px;
			width: 150px;
		}
	}
	&__row {
		display: flex;
		gap: 8px;
		align-items: flex-start;
	}
	&__row-item {
		// min-width: 250px;
		.input__text-field {
			// width: 250px;
			background-color: #f9f9f9;
			border-radius: 4px;
			opacity: 1 !important;
			text-align: center;
		}
		&--firstName,
		&--lastName {
			min-width: 200px;
			width: 200px;
		}
		&--email {
			min-width: 250px;
			width: 250px;
		}
		&--phoneNumber {
			min-width: 180px;
			width: 180px;
		}
		&--shareOwned {
			min-width: 60px;
			width: 60px;
		}
	}
	&__row-country-item {
		&--country-code {
			min-width: 150px;
			margin-top: 4px;
			width: 150px !important;
			height: 48px !important;
			border-radius: 4px;
			display: flex;
			text-align: center;
			align-items: center;
			background-color: #f9f9f9 !important;
			border: 1px solid var(--color-input-border-light);
			border-radius: calc(var(--border-radius) * 0.25);
			font-size: var(--font-size-4);
			line-height: var(--line-height-4);
			padding: calc(var(--padding) * 0.25);
			.css-1s2u09g-control {
				width: 100%;
			}
		}
		&--country-code-options {
			max-height: 200px;
		}
	}
}
.input--isDisabled {
	opacity: 0.6 !important;
	background-color: lightgray !important;
}
