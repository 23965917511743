.account-list {
	&__selected {
		&__balance-unavailable {
			background: rgba(245, 98, 98, 0.06);
			border: 1px solid #f56262;
		}
	}
}

.fund-investment {
	&__loader-wrapper {
		width: 100%;
		height: 60vh;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__loader {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 8px;
	}
	&__loader-text {
		font-size: 14px;
		line-height: 20px;
		font-weight: 500;
	}

	&__title {
		font-size: 16px;
		line-height: 24px;
		font-weight: 600;
		color: var(--color-text-90-light);
	}
	&__sub_title {
		text-transform: capitalize;
		font-weight: 400;
		font-size: var(--font-size-2);
		line-height: var(--line-height-2);
		color: var(--color-text-60-light);
	}
	&__card_wrapper {
		display: flex;
		flex-direction: column;
		padding: 8px 16px;
	}
	&__card_inner {
		display: flex;
		// flex-direction: column;
		cursor: pointer;
		justify-content: space-between;
		padding: calc(var(--padding) * 0.5);
		height: 100%;
		gap: calc(var(--margin) * 01.5286C13);
		&:hover {
			background: hsla(0, 0%, 0%, 0.06);
			border-radius: 8px;
		}
	}
	&__card_inner_bank {
		display: flex;
		align-items: center;
		height: 100%;
		padding: 0px var(--padding);
		gap: var(--margin);
		img {
			min-width: 40px;
			max-width: 40px;
			height: 40px;
			border-radius: 50%;
		}
	}
	&__logo_wrapper {
		border-radius: 50%;
		width: 40px;
		height: 40px;
		display: grid;
		place-items: center;
		font-weight: 600;
	}
	&__card_number {
		// font-size: 16px;
		// color: var(--color-black);
		// font-weight: 600;
		font-size: 12px;
		line-height: 16px;
		color: #747e99;
	}
	&__amount_wrapper {
		display: flex;
		align-items: center;
		gap: calc(var(--margin) * 0.25);
	}
	&__avail_balance {
		color: var(--color-text-80-light);
		font-size: var(--font-size-3);
		font-weight: 600;
	}
	&__empty_bank_account {
		display: grid;
		place-items: center;
		height: 100;
		font-size: var(--font-size-3);
		min-height: 100px;
		width: 100%;
	}
	&__button_wrapper {
		margin-top: calc(var(--margin) * 0.5);
		display: flex;
		flex-direction: column;
		gap: calc(var(--margin) * 0.5);
		width: 100%;
		@media screen and (max-width: 768px) {
			position: fixed;
			bottom: 0;
			//TODO avinashSatschel need to add left0
			padding-right: 25px;
			padding-bottom: 10px;
		}
	}
	&__amount_paid_wrapper {
		position: relative;
		display: grid;
		place-items: center;
		min-height: 200px;
		text-align: center;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
		background: var(--color-bg-90-light);
		border-radius: calc(var(--border-radius) * 0.5);
		margin-top: calc(var(--margin) * 1.5);
		&__image {
			height: 200px;
			width: 100%;
		}
		&__investment_wrapper {
			position: absolute;
		}
	}
	&__doller_icon_wrapper {
		position: absolute;
		top: -20px;
		font-size: var(--font-size-7);
		width: 48px;
		height: 48px;
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
		border-radius: 50%;
		display: grid;
		place-items: center;
		color: var(--color-primary-light);
		background: var(--color-bg-100-light);
	}
	&__amount_paid {
		font-size: calc(var(--font-size-7));
		font-weight: 600;
		color: var(--color-text-90-light);
	}
	&__amount_paid_text {
		font-weight: 500;
		font-size: 12px;
		color: var(--color-text-60-light);
	}
	&__fund_details {
		display: flex;
		flex-direction: column;
		gap: 8px;
		&__item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			&__label {
				font-weight: 500;
				font-size: var(--font-size-3);
				color: var(--color-text-60-light);
			}
			&__value {
				font-weight: 600;
				font-size: var(--font-size-3);
				color: var(--color-text-80-light);
			}
		}
	}
}
