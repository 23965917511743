.InternetConnctionIssue {
	padding: 24px;
	height: 85vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center !important;
	justify-content: center;
	&__wrapper-heading {
		font-weight: 600;
	}
	&__wrapper-subheading {
		font-weight: 400;
		text-align: center;
		line-break: 20px;
	}
	&__instuction-notes-box {
		padding: 24px;
		border: 2px dotted var(--color-orange);
		background-color: #fde5cd;
		color: var(--color-text-light-70);
	}

	&__wrapper {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		gap: 8px;
	}
}

@media only screen and (max-width: 420px) {
	.settings {
		padding: 12px;
	}
}
