.tc_wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	position: absolute;
	inset: 0;
	align-items: center;
	.loader-wrapper {
		display: grid;
		place-items: center;
		position: absolute;
		inset: 0;
	}
	&__body {
		overflow: auto;
		font-size: var(--font-size-3);
		color: #464c5c;
		padding: var(--padding) 10px 0 10px;
		margin-top: 60px;
		width: 100%;
		height: 100%;
		@media screen and (max-width: 500px) {
			height: calc(100vh - 450px);
		}
	}
	&__footer {
		display: flex;
		padding: var(--padding) var(--padding) 0px var(--padding);
		flex-direction: column;
		background: #fff;
		width: 100%;
		position: sticky;
		border-radius: 16px;
		gap: 4px;

		align-self: center;
		@media screen and (max-width: 460px) {
			position: fixed;
			bottom: -1px;
			padding-top: 5px;
		}
		@media screen and (min-width: 500px) {
			width: 100%;
			// border-right: 2px solid hsla(0, 0, 0, 0.18);
			// border-bottom: 2px solid hsla(0, 0, 0, 0.18);
			// border-left: 2px solid hsla(0, 0, 0, 0.18);;
		}
		&__action {
			display: flex;
			gap: calc(var(--margin) * 0.5);
			input[type='checkbox'] {
				transform: scale(1.3);
			}
			label {
				width: 20px !important;
				height: 20px !important;
			}
			&__content {
				cursor: pointer;
				font-size: var(--font-size-3);
				font-weight: 500;
				color: var(--color-text-70-light);
				// height: 22px;
				span {
					color: var(--color-primary-light);
				}
			}
		}
	}
}

input[id='tc-checkbox'] + label,
input[id='policies-checkbox'] + label,
input[id='patriot-checkbox'] + label,
input[id='biometric-checkbox'] + label {
	position: relative;
	display: grid;
	place-items: center;
	min-width: 18px;
	max-width: 18px;
	height: 18px;
	border: 2px solid #d7dff5;
	border-radius: 4px;
	border-radius: 2px;
	cursor: pointer;
	margin-top: 4px;
}
input[id='tc-checkbox']:checked + label,
input[id='policies-checkbox']:checked + label,
input[id='patriot-checkbox']:checked + label,
input[id='biometric-checkbox']:checked + label {
	content: '\2714';
	background-color: var(--color-primary-light);
	border: 2px solid var(--color-primary-light);
	color: #fff;
	font-size: 14px;
}
input[id='tc-checkbox']:checked + label:after,
input[id='policies-checkbox']:checked + label:after,
input[id='patriot-checkbox']:checked + label:after,
input[id='biometric-checkbox']:checked + label:after {
	content: '\2714';
	font-size: 14px;
	margin-top: -4px;
}

.terms_header_container {
	position: relative;
	height: 100%;
}

.privacy:hover {
	cursor: pointer;
}

.otnotice-sections {
	margin-left: 0px !important;
	margin-right: 0px !important;
}

.otnotice-sections > .otnotice-section > .otnotice-section-content {
	margin-left: unset !important;
	margin-right: unset !important;
}

.otnotice-content .otnotice-sections .otnotice-section-content ol,
.otnotice-content .otnotice-sections .otnotice-section-content ul {
	margin: 0 0 1rem;
	padding-inline: 2.5rem;
}

.tc_wrapper__footer__action label {
	width: 24px !important;
	height: 24px !important;
	max-width: 24px !important;
	max-height: 24px !important;
	min-width: 24px !important;
	min-height: 24px !important;
}

.tc_wrapper__footer__action__content {
	font-size: 16px;
}
.process-button {
	&__size {
		height: 58px;
	}
}

.otnotice-sections > .otnotice-section {
	margin-bottom: unset !important;
}
