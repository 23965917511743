.success-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	gap: 24px;
	padding: 16px;
	overflow: auto;
	&__greetings {
		display: flex;
		flex-direction: column;
		gap: 20px;
		justify-content: center;
		align-items: center;
	}
	&__greetings-message {
		color: #2c3039;
		font-size: 20px;
		margin: unset;
		text-align: center;
	}
	&__custom-message {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		text-align: center;
		word-break: break-word;
		margin: unset;
		color: #3d3d3d;
	}
	&__actions {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
	&__app-store-action,
	&__app-store-action,
	&__custom-explore {
		width: 160px;
		height: 48px;
		object-fit: cover;
		background: transparent;
		margin: unset;
		border: none;
		padding: unset;
	}
	&__store,
	&__custom-explore {
		width: 160px;
		height: 48px;
	}
	&__custom-explore {
		padding: 12px;
		border-radius: 4px;
		background: var(--color-primary-light);
		color: #ffffff;
		text-align: center;
		font-size: 14px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-decoration: none;
	}
}
