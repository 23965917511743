.dropdown {
	width: 100%;
}

.rmc-picker-item-selected {
	font-weight: 500;
	color: var(--color-bg-90-dark);
}

.rmc-picker-popup-item {
	font-size: 15px;
	color: var(--color-primary-light);
}

.rmc-picker-popup-header-left {
	color: var(--color-delete);
}

.rmc-picker-item {
	color: var(--color-bg-90-dark);
}

.input-mobile-search {
	padding: 0 10px;
}
.input-mobile {
	&--body {
		flex-direction: 'column';
		height: 300px;
		overflow-y: scroll;
		margin-top: 10px;
		background-color: var(--white);
	}
	&--list {
		border: none;
		padding-block: 8px;
		border-bottom: 1px solid var(--color-input-border-light);
		font-size: 14px;
		background-color: var(--white);
		width: 100%;
	}
}

.option {
	display: flex;
	gap: calc(var(--margin) * 0.25);
	.option-name {
		color: var(--color-text-100-dark);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.option-subname {
		color: var(--color-text-100-dark);
	}
}
.dropdown__error--message {
	color: var(--color-error);
	font-size: 12px;
	margin-top: 8px;
	font-weight: 500;
}
.mobile-drop-down-erro-message {
	background-color: #f5626217;
	border: '1px solid #cc4e4e';
}
