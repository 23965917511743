.identity-verification-new {
	display: flex;
	width: 100%;
	height: 100%;
	padding: 0 24px;
	&__left-container {
		width: calc(70vw - 24px);
		padding: 40px 24px 40px 0;
		display: flex;
		flex-direction: column;
		gap: 24px;

		&__title-container {
			&__label {
				font-weight: 600;
				font-size: var(--font-size-4);
				line-height: calc(var(--line-height-4) * 1.5rem);
				color: var(--color-text-90-light);
				display: flex;
				align-items: center;
				&__back-btn {
					cursor: pointer;
					margin-right: 8px;
					display: flex;
					font-size: 20px;
				}
			}
			&__title {
				font-weight: 600;
				font-size: var(--font-size-6);
				line-height: 36px;
				color: var(--color-text-90-light);
				letter-spacing: 0;
				margin-bottom: 8px;
			}
			&__sub-title {
				font-weight: 400;
				font-size: var(--font-size-4);
				line-height: 24px;
				color: var(--color-text-70-light);
			}
		}

		&__doc-card-container {
			display: flex;
			gap: 6px;
			cursor: pointer;
			flex-direction: column;
			overflow: auto;
			min-height: 150px;
			&::-webkit-scrollbar {
				width: 8px;
			}
			&::-webkit-scrollbar-thumb {
				background-color: rgba(0, 0, 0, 0.1);
				border-radius: 5px;
				-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
			}

			/* Disable the default tap highlight color on mobile browsers (iOS Safari, Android Chrome).*/
			-webkit-tap-highlight-color: transparent;

			@media screen and (min-width: 768px) {
				flex-wrap: wrap;
				flex-direction: row;
			}
			&__item {
				border: 1px solid #dfe3ec;
				border-radius: 8px;
				padding: 12px;
				display: flex;
				gap: 8px;
				text-align: center;
				align-items: center;
				width: 100%;
				min-height: 70px;
				span {
					font-weight: 500;
				}
				@media screen and (min-width: 768px) {
					width: 200px;
					flex-direction: column;
					justify-content: center;
					height: 128px;
				}
				&:hover {
					border: 1px solid #3165b5;
					background-color: #0051cc0f;
					color: #0051cc;
				}
			}
			.no-data-found {
				width: 100%;
			}
		}
	}

	&__right-container {
		width: 60vw;
		background-color: #0000000f;
		padding: 24px;
		margin-right: -24px;
		border-radius: 0 16px 16px 0;
	}

	@media screen and (max-width: 1080px) {
		&__left-container {
			width: 100%;
			padding: 20px 0 40px 0;
		}
		&__right-container {
			display: none;
		}
	}
}


