.camera-container {
	position: relative;
	width: 356px;
	height: 491px;

	video {
		position: absolute;
		clear: unset !important;
		display: unset !important;
		-webkit-transform: unset !important;
		-moz-transform: unset !important;
		z-index: 0 !important;
		transform: unset !important;
		top: 100px;
		right: -100px;
	}

	canvas {
		position: absolute;

		width: 356px !important;
		height: 491px !important;
		z-index: 0 !important;
	}
}

.image-id-card {
	position: absolute;
	top: 0;
	left: 16px;
	right: 16px;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
}

#canvas-verification {
	position: absolute;
	top: 0;
	left: 50%;
	right: 50%;
	bottom: 0;
	transform: translateX(-50%);
}
.checkfraud-camera--loader-wrapper {
	text-align: center;
	width: 100%;
}
.checkfraud-camera__loading-heading {
	font-weight: 500;
	font-size: 16px;
	line-height: 32px;
	text-align: center;
	margin-bottom: 16px;
}
.checkfraud-camera__loading-svg {
	margin-bottom: 24px;
	height: 145px;
}
