.Qr {
	/* Wrapper for the entire QR section */
	&--wrapper {
		width: 100%;
		display: flex;
		height: 100%;
		/* On smaller screens, display items in a column */
		@media screen and (max-width: 680px) {
			flex-direction: column;
		}
	}

	/* Wrapper for the details section */
	&--details_wrapper {
		width: 100%;
		height: 100%;
		background: var(--color-white);
		padding: 24px;
		display: flex;
		flex-direction: column;
		gap: 16px; /* Spacing between children */
	}

	/* Wrapper for the QR code section */
	&--qr_wrapper {
		width: 100%;
		height: 100%;
		background: #0000000f; /* Light black overlay for the background */
		display: flex;
		justify-content: center; /* Center the QR code horizontally */
		align-items: center; /* Center the QR code vertically */
		flex-direction: column;
		gap: 16px; /* Spacing between children */
		padding: 24px;
	}

	/* Block for displaying detailed information */
	&--info_details {
		display: flex;
		flex-direction: column;
		gap: 4px; /* Small spacing between details */
	}

	/* Title styling for the info section */
	&--info_title {
		font-size: var(--font-size-5);
		font-weight: 600; /* Bold text */
		line-height: 28px;
		color: var(--color-text-light-90); /* Light text color */
	}

	/* Description styling for the info section */
	&--info_description {
		font-size: var(--font-size-3);
		font-weight: 400; /* Regular weight text */
		line-height: 20px;
		color: var(--color-text-light-80); /* Lighter text color */
	}

	/* Title styling for the steps section */
	&--steps_title {
		font-size: var(--font-size-3);
		font-weight: 600; /* Bold text */
		line-height: 20px;
		color: var(--color-text-light-90); /* Light text color */
	}

	/* List styling for steps */
	&--steps_list {
		padding-left: 16px; /* Indent for list items */
	}

	/* Individual step list item styling */
	&--steps_list_item {
		font-size: var(--font-size-3);
		font-weight: 500; /* Medium weight text */
		line-height: 24px;
		color: var(--color-text-light-80); /* Lighter text color */
		list-style: decimal; /* Numbered list */
	}

	/* List styling for tips */
	&--tips_list {
		padding-left: 20px; /* Indent for list items */
	}

	/* Container for individual tips */
	&--tips {
		padding: 8px;
		gap: 4px; /* Spacing between tips */
		border-radius: 4px; /* Rounded corners */
		border: 1px dashed #f38a20; /* Dashed orange border */
		background: #e88a2c1f; /* Light orange background */
	}

	/* Styling for each tip list item */
	&--tips_list_item {
		font-size: 14px;
		font-weight: 400; /* Regular weight text */
		line-height: 20px;
		color: var(--color-text-light-90); /* Light text color */
	}

	/* Title styling for the tips section */
	&--tips_title {
		font-size: var(--font-size-4);
		font-weight: 500; /* Medium weight text */
		line-height: 24px;
		color: var(--color-text-light-90); /* Light text color */
	}

	/* Icon styling for tips */
	&--tips-icon {
		font-size: var(--font-size-6);
		color: #f38a20; /* Orange color for icons */
	}

	/* Header section styling for tips, aligned with icons */
	&--tips_header {
		display: flex;
		gap: 4px; /* Spacing between icon and text */
		align-items: center; /* Vertically center items */
	}

	/* Header styling for QR code section */
	&--qr_header {
		font-size: var(--font-size-4);
		font-weight: 600; /* Bold text */
		line-height: 24px;
		text-align: center; /* Center the text */
		width: 70%; /* Set width for better control */
	}

	/* Footer styling for QR code section */
	&--qr_footer {
		text-align: center; /* Center the content */
		display: flex;
		flex-direction: column;
		gap: 16px; /* Spacing between footer items */
	}

	/* Styling for the "OR" and mobile-specific text in QR code section */
	&--qr_or,
	&--qr_mobile {
		font-size: var(--font-size-3);
		font-weight: 400; /* Regular weight text */
		line-height: 20px;
		text-align: center; /* Center the text */
		color: var(--color-text-light-80); /* Light text color */
	}

	/* Styling for desktop-specific text in QR code section */
	&--qr_desktop {
		font-size: var(--font-size-3);
		font-weight: 600; /* Bold text */
		line-height: 20px;
		text-align: center; /* Center the text */
		color: var(--color-primary-light); /* Primary light color */
		cursor: pointer; /* Show pointer on hover */
	}
}

.Qr__Code__Generator {
	/* Wrapper for the QR code, positioned relatively to contain any absolutely positioned elements */
	&--wrapper {
		position: relative;
	}
}
