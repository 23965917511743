.basic_info_container {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	// gap: 16px;
	margin-bottom: 60px;
	// .input {
	// 	height: 62px !important;
	// }
	.input__field {
		font-weight: 400 !important;
	}
	&__disabled {
		pointer-events: none;
	}
	.input__group {
		height: 48px;
	}
	.input__value-container {
		gap: unset !important;
	}
	&__note {
		width: 100%;
		border: 2px dashed #f38a20;
		background-color: #e88a2c1f;
		border-radius: 4px;
		padding: 8px;
		gap: 4px;
		display: grid;

		&__icon {
			color: #f38a20;
			font-size: 24px;
			padding-right: 5px;
		}

		&__title {
			font-size: 16px;
			color: #1d1e21;
			line-height: 24px;
			display: flex;
			font-weight: 500;
			font-family: 'Poppins', sans-serif;
		}
		&__text {
			padding-left: 5px;
			font-weight: 400;
			color: #515767;
		}
	}
}

.input_div_container {
	display: flex;
	gap: 16px;
	margin-top: 10px;
	flex-wrap: wrap;
}

.basic_info_para2 {
	color: #6c7489;
	font-size: 13px;
}

.css-1s2u09g-control {
	border-width: 0px !important;
}

.basic_info_input {
	margin-top: 16px;
	margin-bottom: 16px;
	&--email {
		margin-top: 0;
	}
}
.basic_info__margin {
	margin-top: 16px;
}

.basic_info_button {
	margin-top: 24px;
	@media screen and (max-width: 768px) {
		position: fixed;
		bottom: 0;
		width: 100%;
		//TODO avinashSatschel need to add left0
		// padding-right: 30px;
		background-color: var(--white);
		padding-bottom: 10px;
	}
}

.css-1pahdxg-control {
	border: none !important;
	box-shadow: none !important;
}

// exist flow
.session-wrapper {
	width: 100%;
	display: flex;
	padding: 8px 0px;
	align-items: center;
	gap: 16px;
	align-self: stretch;
	border-bottom: 1px solid var(--color-border-light-100);
	cursor: pointer;

	&__session-icon {
		display: flex;
		padding: 12px;
		text-align: center;
		border-radius: 40px;
		background: var(--color-primary-light, #2251d3);
	}

	&__left-icon {
		width: 24px;
		height: 24px;
		color: white;
	}

	&__right-icon-wrapper {
		margin-left: auto;

		i {
			width: 24px;
			height: 24px;
			color: var(--color-text-light-40);
		}
	}
}

.session_text {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;

	&__1 {
		color: var(--color-text-light-80);
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px;
	}

	&__2 {
		color: var(--color-text-light-70);
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 16px;
	}
}

.basic-info--wrapper {
	width: 100%;
	height: 100%;

	input::-webkit-contacts-auto-fill-button {
		visibility: hidden;
		display: none !important;
		pointer-events: none;
		position: absolute;
		right: 0;
	}
}

.verify-email-container {
	height: 100%;
	.VerifyEmail--container {
		height: 100%;
	}
}
