.LivenessInformation--container {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 16px;
	flex: 1 0 0;
	align-self: stretch;
	flex-direction: row;
	height: 100%;
	width: 100%;

	&__inner {
		display: flex;
		width: 100%;
		flex-wrap: wrap;
	}

	.LivenessInformation--container__content-container {
		height: 100%;
		width: 60vw;
		@media screen and (max-width: 1080px) {
			width: 100%;
		}
	}
	&__content {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 4px;
		padding: 0 20px;
		padding-top: 10px;
		width: 50%;
		.settings {
			height: unset;
			padding: 0;
			padding-top: 10px;
			.settings__wrapper {
				gap: 4px;
				img {
					height: 80px;
					width: 80px;
				}
				.button.button__filled.button__filled--primary.button__large {
					margin-top: 4px;
				}
			}
		}
		@media screen and (max-width: 1080px) {
			margin-bottom: 14px;
		}
		@media screen and (max-width: 780px) {
			margin-bottom: 14px;
			width: 100%;
		}
		&__heading {
			color: #000;
			font-family: poppins;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: 36px;
			@media screen and (max-width: 768px) {
				line-height: 30px;
				margin-top: 20px;
			}
		}

		&__subheading {
			color: var(--color-text-light-80, #515767);
			font-family: poppins;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 27px;
			@media screen and (max-width: 480px) {
				line-height: 20px;
				margin-bottom: 2%;
				display: none;
			}
		}

		&__list {
			padding-left: 20px;
			&__list-item {
				list-style-type: disc;
				color: var(--color-text-light-80, #515767);
				font-family: poppins;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
			}
		}
	}

	&__imageinfo {
		width: 100%;
		height: 100%;
		object-fit: contain;
		@media screen and (max-width: 1080px) {
			max-height: 500px;
		}
	}
	@media screen and (max-width: 768px) {
		&__imageinfo {
			width: 100%;
			margin: 0 auto 6px;
			border-radius: 16px;
			object-fit: contain;
		}
	}

	&__facial-instructions {
		height: auto;
		padding: 8px;
		width: 100%;
		background-color: rgba(232, 138, 44, 0.12);
		border: 1px dashed #f38a20;
		border-radius: 4px;
		margin-bottom: 10px;
		&__tips-heading {
			color: black;
			font-size: 18px;
			font-weight: 600;
		}
		&__ri-thunder {
			color: #f38a20;
		}
	}

	&__image-wrapper {
		height: 100%;
		width: 50vw;
		display: flex;
		position: relative;

		.skelton-loader-wrapper {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background-color: white;
		}
		@media screen and (max-width: 1080px) {
			display: none;
		}
	}
	&__image-wrapper-mobile {
		height: 100%;
		position: relative;
		display: none;
		width: 50%;
		.skelton-loader-wrapper {
			position: absolute;
			z-index: 1;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background-color: white;
			max-width: 100%;
			margin: 0 auto;
		}
		@media screen and (max-width: 1080px) {
			display: block;
			height: 50%;
		}
		@media screen and (max-width: 780px) {
			margin-bottom: 14px;
			width: 100%;
		}
	}
}

.LivenessInformation__webrtc--loader {
	padding: 4px;
	color: var(--color-text-light-80);
	font-weight: 600;
	font-size: 12px;
}

.LivenessInformation__button-begin-liveness {
	background-color: white;
	@media screen and (max-width: 768px) {
		position: fixed;
		left: 0;
		right: 0;
	}
	bottom: 0;
	border: none;
	z-index: 2;
}
.LivenessInformation__button--info {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 20px;
	padding: 4px;
	.button {
		width: 100%;
	}
}

@media screen and (max-width: 1080px) {
	.LivenessInformation__button--info {
		display: flex;
		justify-content: center;
		.button {
			width: 100%;
		}
	}
}

.FacialLoader {
	height: calc(100vh - 100px);
	margin: auto;
}

.camera-dropdown-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	border: 1px solid #ccc;
	border-radius: 4;
	margin-bottom: 2%;
	border-radius: calc(var(--border-radius) * 0.5);
	background-color: #f5f8ff;
	@media screen and (max-width: 1080px) {
		width: unset;
		display: flex;
	}
}

.camera-icon-box {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 48px;
	height: 50px;
	// border: 1px solid #ccc;
	// border-radius: 8px;
	border-right: 1px solid #0000001f;
	background-color: #f5f8ff;
	border-radius: 10px 0 0 10px;
	// border-color: hsl(0, 0%, 80%);
}

.camera-icon {
	font-size: 24px;
	color: #7c8398;
}

.LivenessInformation--container
	.camera-dropdown-container
	#dropdown__container {
	.input__text-field.input__text-field {
		border: none;
	}
}
