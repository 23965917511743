.custom-date-picker {
	display: flex;
	flex-direction: column;
	gap: 4px;
	position: relative;
	width: 100%;
}
.react-date-picker {
	width: 100%;
	height: 48px;
	display: flex;
	align-items: center;
	background: var(--color-input-background);
	border: 1px solid var(--color-input-border-light);
	border-radius: calc(var(--border-radius) * 0.5);
	font-size: var(--font-size-4);
	line-height: var(--line-height-4);
	margin-top: 3px;
	outline-width: 0;
	padding-left: 12px;
}
.error-message {
	color: var(--color-error);
	font-size: var(--font-size-2);
}
.active-error-message {
	border-color: var(--color-error);
	background-color: rgba(255, 0, 0, 0.06);
}

.Calendar {
	position: absolute !important;
	.Calendar__yearText,
	.Calendar__monthText {
		border: 1px solid var(--color-input-border-light);
	}
	.Calendar__yearText::after,
	.Calendar__monthText::after {
		content: '>';
		transform: rotate(90deg);
		margin-left: 6px;
	}
}

@media (max-width: 1500px) {
	.responsive-calendar {
		font-size: 8px !important;
	}
}

@media (max-width: 1200px) {
	.responsive-calendar {
		font-size: 7px !important;
	}
}

/* Large screens */
@media (min-width: 2500px) {
	.responsive-calendar {
		font-size: 12px !important;
	}
}
