.DateCalender {
	position: relative;
	.react-date-picker {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		background: var(--color-input-background);
		border: 1px solid var(--color-input-border-light);
		border-radius: 6px;
		padding: 12px;
		font-size: var(--font-size-4);
		line-height: var(--line-height-4);
		margin-top: 4px;
		outline-width: 0;
	}
	.error-message {
		color: var(--color-error);
		font-size: var(--font-size-2);
	}
	.active-error-message {
		border-color: var(--color-error);
		background-color: rgba(255, 0, 0, 0.06);
	}

	.Calendar {
		position: absolute;
	}
}

@media (max-width: 1500px) {
	.responsive-calendar {
		font-size: 8px !important;
	}
}

@media (max-width: 1200px) {
	.responsive-calendar {
		font-size: 7px !important;
	}
}

/* Large screens */
@media (min-width: 2500px) {
	.responsive-calendar {
		font-size: 12px !important;
	}
}
