.sign-aggrement {
	&__container {
		height: 100%;
		position: relative;

		.esign-signing-screen__container {
			height: calc(100vh - 75px) !important;

			.esign-signing-screen__tnc-container {
				height: calc(100vh - 75px) !important;

				.terms-condition {
					height: calc(100vh - 75px) !important;
				}
			}
		}

		.signing-screen__header,
		.signing-screen__footer {
			width: calc(100% - 48px);
			margin: 0 auto;
			@media screen and (max-width: 480px) {
				width: 100%;
				background-color: var(--color-white);
			}
		}
		&__loader {
			position: absolute;
			inset: 0;
			display: grid;
			place-items: center;
		}
		.signing-dashboard-wrapper {
			height: 100vh;
		}
		.pdf-signer-container {
			max-height: calc(100vh - 194px);
			height: 100%;
			@media screen and (min-width: 1025px) {
				max-height: calc(100vh - 250px);
			}
		}
	}
}

// .pdf-signer-container {
// 	max-height: calc(100vh - 320px) !important;
// }
// .pdf-signer-container {
// 	max-height: calc(100vh - 108px);
// }
// @media (max-width: 1024px) and (orientation: landscape) {
// 	.pdf-signer-container {
// 		max-height: calc(100vh - 240px) !important;
// 	}
// }

@media screen and (max-width: 480px) {
	.sign-aggrement__container {
		height: unset;
	}
	.sign-aggrement {
		&__container {
			height: 100%;
		}
	}
	.pdf-signer-container {
		max-height: calc(100vh - 330px) !important;
	}
}

.sign-agreement-container__loader {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.docusign-iframe-warpper {
	height: 100%;
}
