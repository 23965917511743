.iframe-wrapper-506b {
	position: absolute;
	max-width: 90% !important;
	@media screen and (max-width: 480px) {
		max-width: 100% !important;
	}
	&__iframe {
		width: 100%;
		height: 100%;
		border: none;
		position: absolute;
		inset: 0;
	}
	.signing-screen__header,
	.signing-screen__footer {
		width: calc(100% - 48px);
		margin: 0 auto;
		@media screen and (max-width: 480px) {
			width: 100%;
			background-color: var(--color-white);
		}
	}
}
.loader-506b {
	position: absolute;
	inset: 0;
	display: grid;
	place-items: center;
}
