.mt-2 {
	margin-top: 20px;
}
.input-wrapper-inner {
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;

	&--snn-btn {
		background-color: var(--white);
		//position: fixed;
		//bottom: 0;
		// max-width: var(--window-viewport);
		//padding-right: 0px;
		width: 100%;
		//padding-right: 10px;
		// @media screen and (max-width: 768px) {
		// 	padding-right: 30px;
		// }
	}
}

.provide-ssn__header {
	margin-bottom: 16px;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 4px;
	&__title {
		font-weight: 600;
		font-size: var(--font-size-5);
		line-height: calc(var(--line-height-2) * 1.75);
		color: var(--color-text-90-light);
		letter-spacing: 0;
	}
	&__sub-title {
		font-weight: 400;
		font-size: var(--font-size-2);
		line-height: 16px;
		color: var(--color-text-70-light);
	}
}
