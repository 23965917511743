.ppl-screen-body {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: var(--margin);
	align-items: center;
	@media screen and (min-width: 500px) {
		// min-height: calc(100vh - 218px);
		// max-height: calc(100vh - 90px);
		// margin-bottom: 10px;
		// overflow: auto; //Rahul Singh: to stick the dropdown in kyb
		&::-webkit-scrollbar {
			display: none;
		}
	}
	&__privacy {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: var(--margin);
		align-items: center;
		@media screen and (min-width: 500px) {
			min-height: calc(100vh - 218px);
			max-height: calc(100vh - 90px);
			overflow: clip;
		}
	}
	&__body {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: calc(var(--margin) * 1.5);
		padding: 0px var(--padding);
		width: 100%;
		margin-top: 12px;
		@media screen and (min-width: 500px) {
			// max-width: 768px;
			height: 100%;
			width: 100%;
		}
	}
}
