.label-container {
	padding: var(--padding);
	width: 100%;
	top: 0;
	border-radius: 16px 16px 0 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 8px;
	&__text {
		font-weight: 600;
		font-size: var(--font-size-4);
		line-height: calc(var(--line-height-4) * 1.5rem);
		color: var(--color-text-90-light);
		display: flex;
		align-items: center;
		i {
			margin-right: 10px;
		}
	}

	.skip-btn {
		font-weight: 600;
		color: var(--color-primary-light);
		cursor: pointer;
		font-size: 14px;
		gap: 0;
		i {
			font-size: 20px;
		}
	}

	.header-back-btn {
		display: flex;
		align-items: center;
		cursor: pointer;
		font-weight: 500;
		font-size: 20px;
	}
}
