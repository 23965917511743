.country-label {
	width: 192px;
	&::after {
		height: 100%;
		display: block;
		width: 1px;
		background: var(--color-input-border-light);
		position: absolute;
		right: 0;
		bottom: 0;
		content: '';
	}
	@media only screen and (max-width: 768px) {
		width: 210px;
	}
}

.option {
	display: flex;
	align-items: center;
	gap: 4px;
	&__flag {
		width: 30px;
		height: 20px;
	}
	&__label {
		font-size: 16px;
		display: flex;
		align-items: center;
		gap: 4px;
		text-align: center;
	}
	&__label-code {
		color: var(--color-gray-medium-light);
	}
}

.css-1s2u09g-control {
	background-color: transparent !important;
}

.css-1pahdxg-control {
	border-color: none !important;
}

.css-1pahdxg-control:hover {
	// border-color: none !important;
}

.css-tlfecz-indicatorContainer {
	// display: none !important;
	@media only screen and (max-width: 768px) {
		padding: 8px 8px 8px 0px !important;
	}
}

.skeleton-image-loader-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 18px;
	min-width: 18px;
	min-height: 11px;
	height: 11px;
	background-color: #f0f0f0;
	overflow: hidden;
}

.skeleton-image-loader {
	width: 100%;
	height: 100%;
	background-color: #e0e0e0;
	animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
	0% {
		background-position: -100% 0;
	}
	100% {
		background-position: 100% 0;
	}
}

.skeleton-image-loader {
	background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
	background-size: 200% 100%;
}

.rmc-picker-item-selected {
	font-weight: 500;
	color: var(--color-bg-90-dark);
}

.rmc-picker-popup-item {
	font-size: 15px;
	color: var(--color-primary-light);
}

.rmc-picker-popup-header-left {
	color: var(--color-delete);
}

.rmc-picker-item {
	color: var(--color-bg-90-dark);
}

.input-mobile-search {
	padding: 0 10px;
}
.input-mobile {
	&--body {
		flex-direction: 'column';
		height: 300px;
		overflow-y: scroll;
		margin-top: 10px;
		background-color: var(--white);
	}
	&--list {
		border: none;
		padding-block: 8px;
		border-bottom: 1px solid var(--color-input-border-light);
		font-size: 14px;
		background-color: var(--white);
		width: 100%;
		justify-content: center;
		padding: 8px 16px;
	}
}

.CountryCode {
	&__disabled {
		pointer-events: none;
	}
	&__input-box {
		width: 120px;
		display: flex;
		justify-content: space-between;
		border-right: 1px solid var(--color-input-border-light);
		align-items: center;
		padding: 0px 4px;
		i {
			font-size: 32px;
			color: rgb(204, 204, 204);
		}
	}
	&__flag-container {
		display: flex;
		align-items: center;
		&-flag {
			display: flex;
		}
		.option__label {
			font-weight: 500;
		}
	}
}
