.webgl-wrapper {
	width: 100%;
	height: 100%;
	margin-top: -70px;
	&__inner {
		display: flex;
		flex-direction: column;
		gap: 16px;
		height: 100%;
		width: 100%;
		justify-content: center;
		align-items: center;
		text-align: center;
		padding: 0px 16px;

		&__error-image {
			width: 170px;
			height: 114px;
		}
		&__heading {
			font-size: var(--font-size-5);
			font-weight: 600;
			color: #2c3039;
		}
		&__description {
			color: var(--color-text-light-80);
			font-size: var(--font-size-4);
			font-weight: 400;
			span {
				font-weight: 600;
			}
		}
	}
}
