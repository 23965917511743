.summary__wraper {
	width: 100%;
}

.summary__head {
	display: flex;
	flex-direction: column;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 16px;
	span {
		color: #747e99;
		font-size: 12px;
		font-weight: 400;
	}
}

.summary__desc__wraper {
	display: grid;
	gap: 6px;
}

.summary__desc {
	display: flex;
	color: #747e99;
	font-size: 14px;
	font-weight: 500;
	gap: 8px;
	span {
		color: #515767;
		font-size: 14px;
		font-weight: 600;
		min-width: 150px;
		max-width: 300px;
	}
	b {
		color: #464c5c;
	}
}

.desc_3 {
	margin-bottom: 4px;
}

.desc_4 {
	border-top: 1px solid #d7dff5;
	padding-top: 8px;
	padding-bottom: 16px;
}

.summary__option_choose {
	color: #464c5c;
	font-size: 14px;
	font-weight: 600;
	margin-top: 22px;
}

.summary__option_wraper {
	margin-top: 22px;
	font-size: 14px;
	font-weight: 600;
}

.summary__option_box {
	margin-top: 8px;
	border-radius: 8px;
	height: 56px;
	background: #f4f4f6;
	border: none;
	display: flex;
	align-items: center;
	height: 100%;
	padding: 12px;
	gap: 12px;
	img {
		min-width: 40px;
		max-width: 40px;
		height: 40px;
		border-radius: 50%;
	}
}

.summary__option_desc {
	display: flex;
	flex-direction: column;
	font-size: 14px;
	font-weight: 600;
	span {
		columns: #747e99;
		font-size: 12px;
		font-weight: 500;
	}
}

.summary__logo_wraper {
	border-radius: 50%;
	display: grid;
	place-items: center;
	font-weight: 600;
}

.summary__value_wraper {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.summary__option_value {
	font-size: 16px;
	font-weight: 600;
	float: right;
}
.summary-loader {
	width: 100%;
	height: 100%;
	position: absolute;
	inset: 0;
	display: grid;
	place-items: center;
}

@media (max-width: 480px) {
	.summary__wraper {
		margin-bottom: 100px;
	}
}
