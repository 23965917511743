.scan-wrapper {
	width: 100%;
	height: 100%;

	.camera-btn {
		background: rgba(0, 81, 204, 0.06);
		color: #0051cc !important;
		font-weight: 500;
		font-size: 16px;
		i {
			font-size: 24px;
			font-weight: 500;
		}
	}

	.upload-check-btn {
		margin-bottom: 0px !important;
		cursor: pointer;
		i {
			font-size: 24px;
			font-weight: 500;
		}
	}

	&__inner {
		display: flex;
		flex-direction: column;
		gap: 16px;
		align-items: center;
		height: 100%;
		width: 100%;
		.empty-image-wrapper {
			height: 216px;
		}
		.empty-btn-wrapper {
			display: flex;
			align-items: center;
			gap: 24px;
			width: 100%;
			.upload-check-btn {
				margin-bottom: 0px !important;
				height: 56px;
			}
		}

		.cw {
			display: flex;
			flex-direction: column;
			gap: 16px;
			width: 100%;
			height: 100%;
			align-items: center;
			&__image-wrapper {
				position: relative;
				height: 350px;
				border-radius: 8px;
				.captured-image-wraaper {
					position: absolute;
					bottom: 10px;
					display: flex;
					gap: 10px;
					justify-content: center;
					align-items: center;
					width: 100%;
				}
				&__action {
					display: flex;
					width: 88px;
					padding: 4px 8px;
					justify-content: center;
					align-items: center;
					gap: 4px;
					border-radius: 4px;
					border: 1px solid rgba(0, 0, 0, 0.12);
					background: #fff;
					color: #0051cc;
					font-weight: 500;
					font-size: 12px;
					cursor: pointer;
					i {
						font-size: 18px;
					}
				}
				img {
					height: 350px;
					object-fit: contain;
					border-radius: 8px;
				}
			}
			&__scan {
				border-radius: 8px;
				background: rgba(0, 0, 0, 0.5);
				width: 356px;
				height: 491px;

				video {
					width: 100% !important;
					height: 491px;
					clear: both;
					/* display: block; */
					transform: unset !important;
					-webkit-transform: unset !important;
					-moz-transform: unset !important;
				}
			}
			.button__large {
				max-width: 300px;
			}
		}
	}
}

.cw__camera_inner {
	height: 500px;
	// margin-top: 100px;
}

.transparent-box {
	position: absolute;
	top: 0;
	left: 50%;
	right: 50%;
	bottom: 0;
	transform: translateX(-50%);
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1 !important;
	max-width: 768px;
	max-height: 100vh;

	.close-icon {
		position: absolute;
		top: 8px;
		font-size: 24px;
		font-weight: 500;
		cursor: pointer;
		right: 8px;
		color: #fff;
	}

	.note-text {
		font-size: 12px;
		font-weight: 500;
		color: #fff;
		position: absolute;
		bottom: 8px;
		display: flex;
		flex-direction: column;
		gap: 8px;
		padding-left: 34px;
		padding-right: 34px;
		align-items: center;
		width: 100%;
	}

	.side-layer {
		position: absolute;
		width: 100%;
		background: rgba(0, 0, 0, 0.5);
	}

	.top-layer {
		top: 0;
		left: 34px;
		height: 34px; /* Adjust the height of the top layer as needed */
		background: rgba(0, 0, 0, 0.5);
		width: calc(100% - 68px);
	}

	.bottom-layer {
		bottom: 70px;
		height: 34px;
		left: 34px;
		/* Adjust the height of the bottom layer as needed */
		background: rgba(0, 0, 0, 0.5);
		width: calc(100% - 68px);
	}

	.left-layer {
		left: 0;
		top: 0;
		width: 34px;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
	}

	.right-layer {
		right: 0;
		top: 0;
		width: 34px;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
	}
	.box {
		position: absolute;
		width: 30px;
		height: 30px;
		//  background-color: yellow;
	}
	.box-1 {
		top: 102px;
		left: 32px;
		border-radius: 3.667px 0px 0px 0px;
		border-top: 2px solid #e89f0d;
		border-left: 2px solid #e89f0d;
	}

	.box-2 {
		top: 102px;
		right: 32px;
		border-radius: 0px 3.667px 0px 0px;
		border-top: 2px solid #e89f0d;
		border-right: 2px solid #e89f0d;
	}
	.box-3 {
		bottom: 102px;
		right: 32px;
		border-radius: 0px 0px 3.667px 0px;
		border-bottom: 2px solid #e89f0d;
		border-right: 2px solid #e89f0d;
	}

	.box-4 {
		bottom: 102px;
		left: 32px;
		border-radius: 0px 0px 0px 3.667px;
		border-bottom: 2px solid #e89f0d;
		border-left: 2px solid #e89f0d;
	}
}

@media only screen and (max-width: 420px) {
	.cw__image-wrapper img {
		max-width: calc(100vw - 32px);
	}
}
