.deny-button {
	width: 96px !important;
	justify-content: center;
}

.report-modal__content {
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;
	&__form-body {
		display: flex;
		gap: 16px;
		flex-wrap: wrap;
		max-height: 80vh;
		overflow-y: auto;
		overflow-x: hidden;
		flex-wrap: wrap;
		> div {
			@media screen and (max-width: 500px) {
				width: 100% !important;
			}
		}
	}
	&__btn-wrapper {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	.dollar {
		transform: rotate(-15deg); /* Equal to rotateZ(45deg) */
	}
	.dollar:before {
		content: '$';
	}

	.label-divider-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		gap: 16px;
	}
	.center-option-wrapper {
		margin: auto;
		&__inner {
			border-radius: 50%;
			border: #e5e5e8;
			background: #e5e5e8;
			padding: 12px;
			width: fit-content;
			margin: auto;
			font-weight: 500;
			font-size: 16px;
		}
	}
}

.fetching-details-wrapper {
	display: grid;
	place-items: center;
	width: 100%;
	height: 100%;
	position: absolute;
	inset: 0;

	&__inner {
		display: flex;
		flex-direction: column;
		gap: 8px;
		padding: 24px;
	}
	&__inner-title {
		padding-top: 16px;
		color: var(--color-text-light-90);
		font-size: 24px;
		font-weight: 600;
		text-align: center;
	}
	&__inner-text {
		color: #363a45;
		font-size: 14px;
		font-weight: 500;
		text-align: center;
		color: var(--color-text-light-70);
	}
}
