.error-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 100%;
	position: absolute;
	max-width: 900px;
	margin: auto;
	inset: 0;
	gap: 24px;
	&__image {
		margin: 0 auto; /* center align the image horizontally */
		max-height: 300px;
		@media (min-width: 768px) {
			max-height: 500px;
		}
	}
	&__text-container {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 8px;
	}
	&__heading {
		font-weight: 600;
		font-size: 24px;
		color: #3b393a;
		text-align: center;
	}
	&__desc {
		font-weight: 500;
		font-size: 14px;
		color: #515767;
		text-align: center;
		padding: 0 16px;
		max-width: 768px;
	}
	.default__error-btn {
		padding: 8px 16px;
		background-color: #0051cc0f;
		color: #0051cc;
		font-size: 14px;
	}
}
