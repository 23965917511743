.UnitPrice {
	// Wrapper for the entire component, with column layout and gap between items
	&__wrapper {
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding: 16px 0;

		.input {
			// Resetting the input height to its default value
			height: unset !important;
		}
	}

	// Header section styling
	&__header {
		display: flex;
		flex-direction: column;
		gap: 4px;
	}

	// Title styling with custom color, font size, and line height
	&__title {
		color: var(--color-text-90-light);
		font-size: var(--font-size-4);
		font-weight: 600;
		letter-spacing: 0;
		line-height: calc(var(--line-height-2) * 1.75);
	}

	// Description text styling for the header
	&__description {
		color: var(--color-text-70-light);
		font-size: var(--font-size-2);
		font-weight: 400;
		line-height: 16px;
	}

	// Styling for the fund details section
	&__fund-details {
		display: flex;
		align-items: center;
		gap: 8px;
		width: 100%;
	}

	// Fund label text styling
	&__fund-label {
		color: var(--color-text-60-light);
		font-size: var(--font-size-3);
		font-weight: 500;
	}

	// Fund name styling with a slightly bolder font
	&__fund-name {
		color: var(--color-text-80-light);
		font-size: var(--font-size-3);
		font-weight: 600;
	}

	// Summary box styling with padding and background color
	&__summary {
		display: flex;
		flex-direction: column;
		gap: 4px;
		padding: 8px;
		border-radius: 4px;
		background: var(--color-bg-light-90);
	}

	// Row layout for summary items
	&__summary-row {
		display: flex;
		align-items: baseline;
		gap: 2px;
	}

	// Styling for row labels inside the summary
	&__row-label {
		width: 100%;
		font-size: var(--font-size-3);
		font-weight: 500;
		line-height: 20px;
		color: var(--color-text-light-80);
	}

	// Styling for row values inside the summary
	&__row-value {
		width: 100%;
		padding-left: 8px;
		font-size: var(--font-size-3);
		font-weight: 600;
		line-height: 20px;
		word-break: break-all;
		color: var(--color-text-90-light);
	}

	// Total section styling with border and larger font size for values
	&__total {
		margin-top: 4px;
		padding-top: 4px;
		border-top: 1px solid var(--color-input-border-light);

		// Increase the font size for total value inside the summary
		.UnitPrice__row-value {
			font-size: var(--font-size-5);
		}
	}
}
