.Offline {
	&__container {
		width: 100%;
		height: 100vh;
		margin: 0 auto;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		@media screen and (min-width: 768px) {
			width: 384px;
		}
		gap: 5px;
	}
	&__image {
		width: 192px;
		height: 192px;
	}
	&__text {
		font-size: 20px;
		font-weight: 600;
		line-height: 32px;
		color: var(--black);
	}
	&__desc {
		color: var(--color-text-light-70);
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		text-align: center;
	}
	&__retrybtn {
		width: 120px;
		height: 44px;
		padding: 12px 16px 12px 16px;
		border-radius: 32px;
		font-size: 14px;
		line-height: 20px;
		margin-top: 10px;
	}
}
