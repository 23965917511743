.selfieDetail--container {
	display: flex;
	flex-direction: row;
	gap: 16px;
	flex: 1 0 0;
	align-self: stretch;
	height: 85vh;
	padding: 0 24px;
	@media screen and (min-width: 768px) {
		padding: 0 0 24px 24px;
	}

	.mobile-heading {
		padding-top: 10px;
	}

	&__wrapper {
		width: 60vw;
		justify-content: center;
		padding-top: 2%;
		@media screen and (max-width: 768px) {
			padding-top: unset;
			width: 100%;
		}
	}

	&__content {
		margin-bottom: 16px;
		flex: 1;
		max-width: 600px;

		&__heading {
			color: #000;
			font-family: poppins;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			margin-bottom: 3%;
		}

		&__subheading {
			color: var(--color-text-light-80, #515767);
			font-family: poppins;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
		}

		&__warning {
			color: #800000;
			font-family: poppins;
			font-size: 15px;
			font-style: normal;
			font-weight: 400;
			padding: 5px;
		}
	}

	&__selfie {
		width: 60vw;
		display: flex;
		@media screen and (max-width: 768px) {
			display: none;
		}
	}

	&__selfie-mobile {
		height: 100%;
		@media screen and (max-width: 768px) {
			max-height: calc(100dvh - 230px);
		}
	}

	&__imageinfo {
		width: 100%;
		height: 100%;
		object-fit: cover;
		transform: scaleX(-1);
		border-radius: 16px 0 0 16px;
		margin-right: -24px;
		object-position: top center;
	}
	@media screen and (max-width: 768px) {
		&__imageinfo {
			width: 100%;
			border-radius: 16px;
		}
	}
}

.selfieDetail__button--info {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	.button {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.selfieDetail__button--info-mobile {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	.button {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.selfieDetail__button--sticky {
	background-color: white;
	position: sticky;
	bottom: 0;
	border: none;
}

.mobile-button {
	width: 48%;
}

@media screen and (max-width: 768px) {
	.selfieDetail__button--info {
		display: flex;
		justify-content: center;
	}
}
