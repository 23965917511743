.button {
	display: flex;
	align-items: center;
	border-radius: calc(var(--border-radius) * 0.25);
	background: var(--color-transparent);
	outline: none;
	border: none;
	white-space: nowrap;
	gap: calc(var(--margin) * 0.5);
	height: fit-content;
	width: fit-content;
	transition: color 0.5ms ease-in-out;
	cursor: pointer;

	&__iconFront {
		flex-direction: row-reverse;
	}
	&__filled {
		&--primary {
			color: var(--color-bg-100-light);
			background-color: var(--color-primary-light);
		}
		&--secondary {
			background: var(--color-white-border);
			color: var(--color-bg-80-dark);
		}
		&--danger {
			background: var(--color-error);
			color: var(--color-bg-90-light);
		}
	}
	&__outline {
		border: 1px solid var(--color-primary-dark);
		color: var(--color-primary-dark);
	}
	&__large {
		padding: var(--padding) calc(var(--padding) * 1.5);
		font-size: var(--font-size-4);
		border-radius: calc(var(--border-radius) * 0.5);
	}
	&__block {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__small {
		padding: calc(var(--padding) * 0.5) calc(var(--padding) * 0.75);
		font-size: var(--font-size-2);
		line-height: var(--line-height-2);
	}
	&--disabled {
		opacity: 0.7;
		cursor: not-allowed !important;
		&:hover {
			box-shadow: unset;
		}
	}
}
