.Quantity--container {
	display: flex;
	border: 2px solid #3498db;
	border-radius: 4px;
	overflow: hidden;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	width: 100%;
	justify-content: space-between;
	@media screen and (min-width: 768px) {
		width: 50%;
	}
}

.Quantity--button {
	background-color: #3498db;
	color: #fff;
	border: none;
	cursor: pointer;
	font-size: 20px;
	width: 30px;
	height: auto;
	text-align: center;
	transition: background-color 0.2s;
}

.Quantity--button:hover {
	background-color: #2980b9;
}

.Quantity--inputBox {
	width: 40px;
	text-align: center;
	border: none;
	padding: 8px 10px;
	font-size: 20px;
	outline: none;
	flex: 1;
}

/* Hide the number input spin buttons */
.Quantity--inputBox::-webkit-inner-spin-button,
.Quantity--inputBox::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.Quantity--inputBox[type='number'] {
	-moz-appearance: textfield;
}
