.FaceSetup {
	&__wrapper {
		height: 100vh;
		display: flex;
		align-items: center;
		z-index: 999;
	}
	&__loading-svg {
		margin-bottom: 24px;
		height: 145px;
	}
	&__loading-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 24px;
	}
	&__loading-heading {
		font-weight: 600;
		font-size: 20px;
		line-height: 32px;
		text-align: center;
	}
	&__loading-instructions {
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
		align-items: center;
		color: #5d657a;
		margin-bottom: 32px;
		text-align: center;
	}
}

@media only screen and (max-width: 600px) {
	.FaceSetup__loading-wrapper {
		padding: unset;
	}
	.FaceSetup__loading-heading {
		font-size: var(--font-size-3);
		line-height: 20px;
	}
	.FaceSetup__loading-instructions {
		font-size: var(--font-size-2);
	}
}
