.Qr__Success {
	&--wrapper {
		/* Full height container with centered content and gap between elements */
		height: 100%;
		display: flex;
		flex-direction: column;
		gap: 8px;
		justify-content: center;
		align-items: center;
		padding: 24px;
	}

	&--image-wrapper {
		/* Icon container with fixed width and height */
		width: 200px;
		height: 200px;
	}

	&--icon-wrapper {
		width: 130px;
		height: 130px;
		.qr-success-icon {
			font-size: 96px;
			display: flex;
			justify-content: center;
			color: var(--color-light-orange);
		}
	}

	&--details,
	&--footer {
		/* Flex containers for details and footer, centered content with vertical gap */
		display: flex;
		flex-direction: column;
		gap: 8px;
		align-items: center;
		justify-content: center;
	}

	&--details_title {
		/* Centered title text with larger font size, bold weight */
		text-align: center;
		font-size: var(--font-size-4);
		font-weight: 600;
		line-height: 24px;
	}

	&--details_description {
		/* Centered description text with medium font size, normal weight */
		text-align: center;
		font-size: var(--font-size-3);
		font-weight: 400;
		line-height: 20px;
	}

	&--footer-text {
		/* Centered footer text with larger font size, normal weight */
		font-size: var(--font-size-4);
		font-weight: 400;
		line-height: 24px;
		text-align: center;
	}

	&--details {
		/* Adjust margin for details section to move content upwards */
		margin-top: -36px;
	}

	&--footer {
		/* Footer padding and styling for large button */
		padding-top: 16px;

		.button__large {
			/* Large button with fixed height and dynamic padding */
			height: 48px;
			padding: var(--padding) calc(var(--padding) * 2);
		}
	}
}

.loading-steps {
	// Wrapper for the QR success footer, controlling its width
	.Qr__Success--footer_wrapper {
		max-width: 768px;
		width: 60%; // Sets the width of the footer wrapper to 60% of its parent container
	}
}
