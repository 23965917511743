.bank-account-detail {
	border-radius: 8px;
	height: 100%;
	background: rgba(47, 50, 61, 0.06);
	border: 1px solid transparent;
	&__selected {
		background: rgba(60, 101, 214, 0.12);
		border: 1px solid #3c65d6;
	}
}

.mainComponent__loader {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.fw-500 {
	font-weight: 500 !important;
}
.account-list {
	padding-top: 4px;
	padding-bottom: 4px;
}

.Fund-confirmation-modal {
	width: 96%;
	@media screen and (min-width: 768px) {
		width: 600px;
	}
	.Modal__wrapper {
		padding: unset;
		padding-bottom: 16px;
	}
	&__main {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	&__img {
		min-height: 100px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	&__top {
		min-height: 150px;
		background-color: #e1e6f5;
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
		padding: 16px;
	}
	&__title {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 10px;
		font-weight: 700;
	}
	&__content {
		display: flex;
		flex-direction: column !important;
		padding: 24px;
		width: 100%;
		p {
			color: #6c7489;
			font-size: 14px;
			margin-bottom: 20px;
		}
		span {
			color: #6c7489;
			font-size: 16px;
			line-height: 24px;
			b {
				font-size: 14px;
			}
		}
	}
	&__footer {
		display: flex;
		justify-content: flex-end;
		gap: 10px;
		align-items: center;
		width: 100%;
		padding: 16px;
		@media screen and (max-width: 500px) {
			flex-direction: column-reverse;
		}
	}
	&__button {
		margin-top: 10px;
		width: 20% !important;
		@media screen and (max-width: 500px) {
			width: 100% !important;
		}
	}
}
